import { SvgIcon, Typography } from "@material-ui/core";
import { allLaunchpads } from "src/helpers/AllLaunchpad";

function LaunchpadSocial({ pidx }) {
  return (
    <>
      <div id="launchpad-social">
        <div>
          <div>
            <Typography variant="h6">Website</Typography>
          </div>
          <div>
            <Typography variant="h6">
              <a href={allLaunchpads[pidx].websiteUrl} target="_blank">
                {allLaunchpads[pidx].websiteName}
              </a>
            </Typography>
          </div>
        </div>
        <div>
          <div>
            <Typography variant="h6">Social Media</Typography>
          </div>
          <div className="socialmedia-icon">
            <Typography variant="h6">
              {allLaunchpads[pidx].socialMedia && allLaunchpads[pidx].socialMedia.length > 0
                ? allLaunchpads[pidx].socialMedia.map((el, i) => (
                    <a key={i} href={el.url.includes("https") ? el.url : `mailto:${el.url}`} target="_blank">
                      <SvgIcon component={el.logo} />
                    </a>
                  ))
                : ""}
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
}

export default LaunchpadSocial;
