import { useRef, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@material-ui/core";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import useTheme from "src/hooks/useTheme";

function DashboardChart() {
  const { totalSnapSupply, snapDistribution } = useSelector(state => state.snapDash);
  const [theme] = useTheme();
  const smallerScreen = useMediaQuery("(max-width: 650px)");

  const chart = useRef(null);

  useLayoutEffect(() => {
    am4core.useTheme(am4themes_animated);

    let x = am4core.create("chartdiv", am4charts.PieChart3D);

    // x.radius = am4core.percent(90);
    // smallerScreen ? (x.innerRadius = am4core.percent(20)) : (x.innerRadius = am4core.percent(30));
    smallerScreen ? (x.innerRadius = am4core.percent(20)) : (x.innerRadius = am4core.percent(20));
    // smallerScreen ? (x.radius = am4core.percent(50)) : (x.radius = am4core.percent(90));
    smallerScreen ? (x.radius = am4core.percent(50)) : (x.radius = am4core.percent(65));

    let pieSeries = x.series.push(new am4charts.PieSeries3D());
    pieSeries.dataFields.value = "litres";
    pieSeries.dataFields.category = "Holder";
    pieSeries.ticks.template.disabled = false;
    // smallerScreen ? (pieSeries.ticks.template.disabled = true) : (pieSeries.ticks.template.disabled = false);
    // theme === "light"
    //   ? (pieSeries.labels.template.fill = am4core.color("black"))
    //   : (pieSeries.labels.template.fill = am4core.color("white"));
    pieSeries.labels.template.fill = am4core.color("#fff");
    // pieSeries.labels.template.radius = am4core.percent(0);
    smallerScreen
      ? (pieSeries.labels.template.radius = am4core.percent(0))
      : (pieSeries.labels.template.radius = am4core.percent(0));
    pieSeries.labels.template.maxWidth = 100;
    pieSeries.labels.template.wrap = true;
    smallerScreen ? (pieSeries.labels.template.fontSize = 10) : (pieSeries.labels.template.fontSize = 14);
    pieSeries.slices.template.propertyFields.fill = "color";
    pieSeries.slices.template.tooltipText = "{category}: {value.value.formatNumber('#.00')}";
    let hoverChart = pieSeries.slices.template.states.getKey("hover");
    hoverChart.properties.shiftRadius = 0;
    hoverChart.properties.scale = 1.1;
    hoverChart.properties.fillOpacity = 0.9;

    let chartData = [
      {
        Holder: "Deployer",
        litres: snapDistribution.deployer,
        color: "#F4831F",
      },
      {
        Holder: "SnapEx",
        litres: snapDistribution.snapex,
        color: "#FF9933",
      },
      {
        Holder: "Launchpad",
        litres: snapDistribution.launchpad,
        color: "#C97827",
      },
      {
        Holder: "Public",
        litres:
          totalSnapSupply -
          (snapDistribution.deployer + snapDistribution.snapex + snapDistribution.launchpad + snapDistribution.pancake),
        color: "#FFA500",
      },
      {
        Holder: "PancakeSwap",
        litres: snapDistribution.pancake,
        color: "#F4C194",
      },
    ];

    x.data = chartData;

    chart.current = x;

    return () => {
      x.dispose();
    };
  }, [totalSnapSupply, snapDistribution, theme, smallerScreen]);

  return (
    <>
      <div
        id="chartdiv"
        style={{ width: "100%", height: "400px" }}
        hidden={
          !snapDistribution.snapex ||
          !snapDistribution.launchpad ||
          !snapDistribution.pancake ||
          !snapDistribution.deployer
        }
      ></div>
    </>
  );
}

export default DashboardChart;
