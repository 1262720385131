import { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
import { getFundProgress } from "src/redux/launchpadActions";

function LaunchpadProgress({ project }) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let active = true;
    fundProgress();
    return () => {
      active = false;
    };
    async function fundProgress() {
      let prog = await getFundProgress(project.projectAbi, project.projectAddress, project.target);
      if (!active) return;
      setProgress(prog);
    }
  }, [project]);

  return (
    <div className="progress-bar-1">
      <div
        className="progress-1"
        style={
          project.raised && project.target
            ? { width: `${(project.raised / project.target) * 100}%` }
            : { width: `${progress}%` }
        }
      ></div>
    </div>
  );
}

export default LaunchpadProgress;
