import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Paper, Link, Tab, Tabs, TextField, Typography } from "@material-ui/core";
import { TabContext, TabPanel } from "@material-ui/lab";
import "./snapStaking.scss";
import Deposit from "./Deposit";
import Withdraw from "./Withdraw";
import PoolInfo from "./PoolInfo";

function StakingDetail({ pool, poolFigure, unlockBlock }) {
  const [tab, setTab] = useState("deposit");

  const handleChange = (e, newValue) => {
    setTab(newValue);
  };

  return (
    <>
      <div className="stakedetail">
        <TabContext value={tab}>
          <Tabs
            value={tab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            aria-label="pool detail"
          >
            <Tab label="DEPOSIT" value="deposit" />
            <Tab label="WITHDRAW" value="withdraw" />
            <Tab label="INFO" value="info" />
          </Tabs>
          <TabPanel value="deposit">
            <Deposit pool={pool} />
          </TabPanel>
          <TabPanel value="withdraw">
            <Withdraw pool={pool} poolFigure={poolFigure} unlockBlock={unlockBlock} />
          </TabPanel>
          <TabPanel value="info">
            <PoolInfo pool={pool} />
          </TabPanel>
        </TabContext>
      </div>
    </>
  );
}

export default StakingDetail;
