import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button, Grid, Paper, Link, TextField, Typography, useMediaQuery, Zoom } from "@material-ui/core";
import "./snapStaking.scss";
import allPools from "src/helpers/AllPools";
import StakingRow from "./StakingRow";

function SnapStaking() {
  const dispatch = useDispatch();
  const smallerScreen = useMediaQuery("(max-width: 650px)");
  const verySmallScreen = useMediaQuery("(max-width: 379px)");
  const [zoomed, setZoomed] = useState(false);
  const [selected, setSelected] = useState("ALL");
  const [search, setSearch] = useState("");

  const filterBtn = [
    { label: "MY POOLS", keyword: "MYPOOLS" },
    { label: "ALL", keyword: "ALL" },
    { label: "SNAP", keyword: "SNAP" },
    { label: "LP", keyword: "LP" },
    // { label: "BTC", keyword: "BTC" },
    // { label: "ETH", keyword: "ETH" },
    // { label: "NEW PAIRS", keyword: "NEW PAIRS" },
    // { label: "OTHERS", keyword: "OTHERS" },
  ];

  const columnName = [
    { label: "Pool Name", clickable: true },
    { label: "Earned", clickable: true },
    { label: "APY", clickable: true },
    { label: "My Deposit", clickable: true },
    { label: "TVL", clickable: true },
    { label: "", clickable: false }, // Placeholder
  ];

  const handleFilter = keyword => {
    setSelected(keyword);
  };

  const searchHelper = pool => {
    return (
      pool.name.toLowerCase() +
      pool.formula.toLowerCase() +
      pool.address.toLowerCase() +
      pool.stakeToken.toLowerCase() +
      pool.stakeTokenAddress.toLowerCase() +
      pool.rewardToken.toLowerCase() +
      pool.rewardTokenAddress.toLowerCase()
    );
  };

  return (
    <>
      <div className="snapstake">
        <div id="snapstake-view">
          <Zoom in={true} onEntered={() => setZoomed(true)}>
            <Paper className="snapstake-card">
              <div className="header">
                <div className="title">
                  <div className="staking-title">
                    <Typography variant="h4">
                      <b>Stake LP Tokens</b>
                    </Typography>
                  </div>
                  <div className="staking-search">
                    <TextField
                      label="Search"
                      variant="outlined"
                      size={"small"}
                      color="primary"
                      onChange={e => setSearch(e.currentTarget.value)}
                    />
                  </div>
                </div>
                <div className="snapstake-filterbtn">
                  {filterBtn.map((el, i) => (
                    <Button
                      key={i}
                      variant="outlined"
                      onClick={() => handleFilter(el.keyword)}
                      className={selected === el.keyword ? "selected" : ""}
                    >
                      <div>{el.label}</div>
                    </Button>
                  ))}
                </div>
                <div className="column-name">
                  {columnName.map((el, i) => (
                    <div key={i}>
                      <div>
                        <b>{el.label}</b>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {selected !== "MYPOOLS" // If !== MYPOOLS, filter & search pool keywords
                ? allPools
                    .filter(el => el.category.includes(selected))
                    .filter(el => searchHelper(el).includes(search.toLowerCase()))
                    .map((el, i) => <StakingRow key={i} pool={el} selected={selected} />)
                : allPools // If selected === MYPOOLS, pass in all pools and filter in child
                    .filter(el => searchHelper(el).includes(search.toLowerCase()))
                    .map((el, i) => <StakingRow key={i} pool={el} selected={selected} />)}
            </Paper>
          </Zoom>
        </div>
      </div>
    </>
  );
}

export default SnapStaking;
