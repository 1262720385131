import { CAL_APY, USER_SNAP_BAL } from "./calculatorActions";

const initialState = {
  apy: "",
  userSNAPBal: "",
  userROI: "",
};

export function calculatorReducer(state = initialState, action) {
  switch (action.type) {
    case CAL_APY:
      return { ...state, apy: action.payload };
    case USER_SNAP_BAL:
      return { ...state, userSNAPBal: action.payload.accountBalance, userROI: action.payload.userROI };
    default:
      return state;
  }
}
