import { Typography } from "@material-ui/core";
import sproutie1 from "src/assets/launchpad/sproutie/sproutie1.png";
import sproutie2 from "src/assets/launchpad/sproutie/sproutie2.png";
import sproutie3 from "src/assets/launchpad/sproutie/sproutie3.png";

function CreoTab1() {
  return (
    <>
      <Typography vairant="h6" color="textSecondary">
        Blockchain Gaming Ecosystem and Gamefi Developer
      </Typography>
      <br />
      <br />
      <br />
      <Typography variant="h5" color="textSecondary">
        HIGHLIGHTS
      </Typography>
      <br />

      <ul>
        <li>
          <Typography variant="h6" color="textSecondary">
            High Quality Gamefi with Interchangeable Assets/NFTs all under one healthy ecosystem.
          </Typography>
          <br />
        </li>
      </ul>
      <br />
      <br />
      <br />

      <Typography variant="h5" color="textSecondary">
        PRODUCT
      </Typography>
      <br />
      <Typography variant="h3" color="textSecondary">
        <b>Creo Engine: One Token, One Ecosystem</b>
      </Typography>
      <br />
      <Typography variant="h6">
        Creo Engine is a gaming blockchain platform dedicated to game developers worldwide to launch their games,
        similar to how the Steam store distributes its library of games, we’ve named the platform ‘Creoverse’. Aside
        from Creoverse, Creo Engine also serves as a game developer aiming to provide the best gaming experience for its
        players, the games are developed through our in-house studio, ‘Nomina Games’. All games under Creo Engine
        Ecosystem are connected through the Assets Interchangeability feature. The platform has its in-house developer
        tools for developers to utilize in onboarding their games to our platform as well. Creo Engine also built a
        marketplace and a scholarship program for every level of players.
      </Typography>

      <br />
      <br />
      <br />

      <Typography variant="h5" color="textSecondary">
        PROBLEMS
      </Typography>
      <br />
      <Typography vairant="h6">
        Stagnant NFTs in terms of utilization; Most NFTs in games are only functional in one game and incompatible in
        others. When one game dies, players’ hard-earn assets and time invested will become obsolete. We also think the
        Play-to-Earn games nowadays are too blatantly crypto and somehow lost the essence of what conventional video
        games offer; a high-quality game experience.
      </Typography>

      <br />
      <br />
      <br />

      <Typography variant="h5" color="textSecondary">
        SOLUTION
      </Typography>
      <br />
      {/* <img src={sproutie1} alt="Sproutie Solution" style={{ width: "100%" }} /> */}
      <br />
      <br />
      <Typography variant="h6">
        We want to think that when essential NFTs in the gaming world, for example, characters, weapons, and
        miscellaneous items, are usable in more than one game, their value will progressively increase. Such a condition
        will give users the confidence to invest their hard-earned money. Players can also earn profit while enjoying
        high-quality video games on par with conventional games.
      </Typography>

      <br />
      <br />
      <br />
      <Typography variant="h5">MARKET</Typography>
      <br />
      <br />
      <Typography variant="h6">
        Creo Engine aims to market its game to the general mobile game players in Asia. We could provide a high-quality
        gaming experience for conventional gamers while slowly introducing Play-to-Earn mechanics. As time goes by,
        players everywhere will utilize gaming not only as a means of entertainment but through a healthy ecosystem;
        gaming can also be a tool to provide welfare, not only in Asia but perhaps also in the world.
      </Typography>
      <br />
      <br />
      <Typography variant="h5" color="textSecondary">
        INVESTORS & PARTNERS
      </Typography>
      <br />
      <ul>
        <li>
          <Typography variant="h6">Moonwhale</Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">Tokocrypto</Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">Kommunitas</Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">Cointelligence</Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">Gusta Capital</Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">Crypto League</Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">CuanB Capital</Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">SkyX Fund</Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">Traveler Capital</Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">SMO Capital</Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">Gusta Captial</Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">Yay Games</Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">Babylons</Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">Infinity Pad</Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">Synapse Network</Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">More</Typography>
          <br />
        </li>
      </ul>
      <br />
      <br />
      <br />

      <Typography variant="h5" color="textSecondary">
        TOKEN UTILITY
      </Typography>
      <br />
      <Typography variant="h6" color="textSecondary">
        $CREO
      </Typography>
      <br />
      <ul>
        <li>
          <Typography variant="h6">
            Governance token in our Ecosystem:
            <br />
            All games that run under the Creo Engine platform will only use one game token; the CREO token.
          </Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">
            Token Staking program to earn rewards
            <br />
            Users/gamers that stake their Creo tokens will receive Creo tokens as rewards.
          </Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">
            Gas fees for marketplace transaction
            <br />
            All transactions in the marketplace will be charged with a small amount of CREO token as transaction fees.{" "}
          </Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">
            In-game and marketplace transactions
            <br />
            All transactions occurred in the marketplace use the CREO token.
          </Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">
            Liquidity
            <br />
            Creo Tokens will also be used as liquidity in exchanges.
          </Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">
            Staking for Developers
            <br />
            Game developers are required to stake a certain amount of CREO tokens before joining the ecosystem.
          </Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">
            Incubation Program
            <br />
            Creo Engine will use the Creo token in the project incubation program.
          </Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">
            Rewards
            <br />
            Creo Engine will occasionally give away Creo tokens in promotional events as a reward.
          </Typography>
          <br />
        </li>
      </ul>
    </>
  );
}

export default CreoTab1;
