const switchRequest = (chainId: string) => {
  switch (chainId) {
    case "56":
      return window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x38" }],
      });
    case "97":
      return window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x61" }],
      });
  }
};

const addChainRequest = (chainId: string) => {
  switch (chainId) {
    case "56":
      return window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: "0x38", // 56
            chainName: "Smart Chain",
            rpcUrls: ["https://bsc-dataseed.binance.org/"],
            blockExplorerUrls: ["https://bscscan.com"],
            nativeCurrency: {
              name: "BNB",
              symbol: "BNB",
              decimals: 18,
            },
          },
        ],
      });
    case "97":
      return window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: "0x61", // 97
            chainName: "Smart Chain - Testnet",
            rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
            blockExplorerUrls: ["https://testnet.bscscan.com"],
            nativeCurrency: {
              name: "BNB",
              symbol: "BNB",
              decimals: 18,
            },
          },
        ],
      });
    default:
      return window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: "0x38", // 56
            chainName: "Smart Chain",
            rpcUrls: ["https://bsc-dataseed.binance.org/"],
            blockExplorerUrls: ["https://bscscan.com"],
            nativeCurrency: {
              name: "BNB",
              symbol: "BNB",
              decimals: 18,
            },
          },
        ],
      });
  }
};

export const switchNetwork = async (chainId: string) => {
  if (window.ethereum) {
    try {
      await switchRequest(chainId);
    } catch (error: any) {
      if (error.code === 4902) {
        try {
          await addChainRequest(chainId);
          await switchRequest(chainId);
        } catch (addError) {
          console.log(error);
        }
      }
      console.log(error);
    }
  }
};
