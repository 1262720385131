import "./snapStaking.scss";

function Progress({ approveStatus }) {
  return (
    <>
      <div className="progress-bar">
        <div className={approveStatus ? "progress-point approved" : "progress-point"}>1</div>
        <div className={approveStatus ? "progress-line approved" : "progress-line"}></div>
        <div className="progress-point">2</div>
      </div>
    </>
  );
}

export default Progress;
