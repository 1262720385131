import { Typography } from "@material-ui/core";
import strtPitch1 from "src/assets/launchpad/strt/strtImg1.png";

function StrtButtonTab1() {
  return (
    <>
      <Typography variant="h5" color="textSecondary">
        HIGHLIGHTS
      </Typography>
      <br />
      <ul>
        <li>
          <Typography variant="h6" color="textSecondary">
            Multi-Chain DApp as a service to the entire web 3.0 ecosystem by providing a Decentralize Token Management
            Platform
          </Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6" color="textSecondary">
            Providing an instant and independent multi-chain platform to generate token, airdrop, smart contract, token
            sale pool, fair launch, & launches blockchain project within a few clicks
          </Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6" color="textSecondary">
            Backer & Partners : Creo Engine, SkyX Venture, Automatic Venture Group, BTW Foundation, Platinum Fund,
            Babylons, Audit Rate Tech, SnapEx, Core Starter, MilkyWay Finance, KSM Starter, Erax, Starterxyz, 0xPad.
          </Typography>
          <br />
        </li>
      </ul>
      <br />
      <br />
      <br />

      <Typography variant="h5" color="textSecondary">
        PRODUCT
      </Typography>
      <br />
      <Typography variant="h3" color="textSecondary">
        <b>RAISE FUND WITHOUT BARRIERS</b>
      </Typography>
      <br />
      <ul>
        <li>
          <Typography variant="h6" color="textSecondary">
            Token Generator : Create your own Token in less than a minute with the most used Smart Contract Generator
            for BEP20 token (more network coming soon). No login. No setup. No coding required.
          </Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6" color="textSecondary">
            Airdrop Dashboard : Run your project promotional program by using our Airdrop tool as a tool to distribute
            these rewards in a very instant and simple way.
          </Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6" color="textSecondary">
            Smart Contract Generator : Worriless about solidity engineer, starts from token sale smart contract,
            liquidity, to vesting; we can handle it for you so that all your fundraise can still run in a transparent
            and decentralized way.
          </Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6" color="textSecondary">
            Independent Launchpad Pool : Create their own Launchpad Pool by raise fund Independently as they need,
            without involving any third party at all, suited to anyone, anytime, anywhere.
          </Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6" color="textSecondary">
            Liquidity Dashboard : Providing a transparent raised fund by showing to public the whole project liquidity
            including yours.
          </Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6" color="textSecondary">
            Trust Badge : Project creators have an freedom to put more effort to gain trust from investor by getting
            Audit, KYC, & Premium badge from STRT Button.
          </Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6" color="textSecondary">
            Anti-Rug : Lock your liquidity and token using smart contract by a certain time for security reasons to
            investors and creators so there’s no dumping the market and taking out the liquidity.
          </Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6" color="textSecondary">
            VIP Access : Private Pool for investors who lock up their $STRT tokens on our platform by earning a purchase
            price of 20% - 100% cheaper than those offered on Public Pool.
          </Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6" color="textSecondary">
            Accelerator : Assist & incubate good quality Start-Ups in initial project funding before entering the Public
            Fundraising stage. Start-Ups will get initial funding in order to build prototypes to Beta Testing so that
            when entering Public Fundraise, it will increase investors’ trust.
          </Typography>
          <br />
        </li>
      </ul>
      <br />
      <br />
      <br />

      <Typography variant="h5" color="textSecondary">
        PROBLEM & SOLUTION
      </Typography>
      <div>
        <img src={strtPitch1} alt="STRT Solutions" style={{ width: "100%" }} />
      </div>
      <br />
      <br />
      <br />

      <Typography variant="h5" color="textSecondary">
        INVESTORS
      </Typography>
      <br />
      <Typography variant="h3" color="textSecondary">
        <b>BIG BRANDS IN THE MARKET</b>
      </Typography>
      <br />
      <Typography variant="h6" color="textSecondary">
        Creo Engine, SkyX Venture, Automatic Venture Group, BTW Foundation, Platinum Fund, Babylons, Audit Rate Tech,
        SnapEx, Core Starter, MilkyWay Finance, KSM Starter, Erax, Starterxyz, 0xPad.
      </Typography>
      <br />
      <br />
      <br />

      <Typography variant="h5" color="textSecondary">
        TEAM
      </Typography>
      <br />
      <Typography variant="h3" color="textSecondary">
        <b>STRT BUTTON TEAM</b>
      </Typography>
      <br />
      <Typography variant="h6" color="textSecondary">
        The team has a minimum of 5 years experience in their respective fields, especially in the field of blockchain
        technology.
      </Typography>
      <br />
      <ul>
        <li>
          <Typography variant="h6" color="textSecondary">
            Jimmy Yan | Founder & CEO | Since 2012 experienced in building several startups from scratch and generated
            income from it. Instead of competing with others to change the world by "creating" a new Blockchain
            Technology, we decided to "empower" Blockchain by building a Fundraising Platform to help StartUp Creators
            get funding in a Decentralized & Transparent way.
          </Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6" color="textSecondary">
            Anon | Solidity Engineer Fullstack developer with more than 7 years of experience in software development,
            was involved with various projects from game to web, mobile development, and currently working on blockchain
            and focusing on pushing more quality DApps into the market. Experienced in smart contract with ERC20 and
            BEP20.
          </Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6" color="textSecondary">
            Core Engineer Team With more than 10 years of experience in programming, UI and UX development, providing
            professional front-end and back-end teams developing responsive internet and databases for various
            technology projects and software such as e-commerce, remittance, etc.
          </Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6" color="textSecondary">
            More about our Team : https://docs.strtbutton.com/english/raise-money-without-barriers/team
          </Typography>
          <br />
        </li>
      </ul>
      <br />
      <br />
      <br />

      <Typography variant="h5" color="textSecondary">
        TOKEN UTILITY
      </Typography>
      <br />
      <Typography variant="h3" color="textSecondary">
        <b>$STRT: THE NEW ERA OF IDO PLATFORM</b>
      </Typography>
      <br />
      <ul>
        <li>
          <Typography variant="h6" color="textSecondary">
            Listing Fee : Every Startup that intends to register a project on the STRT Button IDO platform will be
            charged a Signup Fee & Success Fee paid using $STRT tokens, by purchasing tokens directly from the market.
          </Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6" color="textSecondary">
            VIP Access: In order to get special access by joining the Private Pool of each IDO, investors are required
            to lock or stake a certain amount of their $STRT tokens for several months and will be unlocked after that
            time period expires, also a penalty will apply if the tokens are unlocked before that time period.
          </Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6" color="textSecondary">
            Accelerator Fund : The funding we provide in the Accelerator & Incubate program comes from every $STRT token
            belonging to Community Shares and from the Fundraise funds we received during the $STRT IDO, in return the
            STRT Button has "Ownership" in the form of Tokens, Company Shares, or Success Fees from the total
            Fundraising generated, then the profits will be used to buy back the $STRT token market circulating on the
            exchange.
          </Typography>
          <br />
        </li>
      </ul>
      <br />
      <br />
      <br />
    </>
  );
}

export default StrtButtonTab1;
