import axios from "axios";
import Web3 from "web3";
const web3 = new Web3(window.ethereum);
import { metamaskErrorWrap } from "./MetamaskErrorWrap";
import { error, info, success } from "../slices/MessagesSlice";
import { switchNetwork } from "./SwitchNetwork";
import { chainId } from "src/constants";
var BN = require("big-number");

export const RERENDER = "RERENDER";

export const getAccount = async () => {
  try {
    if (window.ethereum) {
      let accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      if (accounts[0]) {
        return accounts[0];
      }
    } else {
      return false;
    }
  } catch (err) {
    console.log(err);
  }
};

export const checkNetwork = networkName => async dispatch => {
  try {
    if (window.ethereum) {
      const network = await window.ethereum.request({
        method: "net_version",
      });
      if (network !== chainId[networkName]) {
        switchNetwork(chainId[networkName]);
        dispatch(info("Switching network.."));
      }
    }
  } catch (err) {
    console.log(err);
  }
};

export const checkAccountBalance = async (tokenAbi, tokenAddress) => {
  try {
    let tokenContract = new web3.eth.Contract(tokenAbi, tokenAddress);
    let userAddress = await getAccount();
    let accountBalance = userAddress ? await tokenContract.methods.balanceOf(userAddress).call() : "-";
    let converted = (accountBalance / 10 ** 18).toString();
    if (converted.indexOf(".") > 0) {
      return converted.slice(0, converted.indexOf(".") + 7);
    } else {
      return converted;
    }
  } catch (err) {
    console.log(err);
  }
};

export const getDecimals = async (tokenAbi, tokenAddress) => {
  try {
    let tokenContract = new web3.eth.Contract(tokenAbi, tokenAddress);
    let dec = await tokenContract.methods.decimals().call();
    return dec;
  } catch (err) {
    console.log(err);
  }
};

export const getPoolFigures = async (
  poolAbi,
  poolAddress,
  stakeTokenAbi,
  stakeTokenAddress,
  rewardTokenAbi,
  rewardTokenAddress,
  apyType,
) => {
  try {
    let apy;
    let tvl;
    let poolContract = new web3.eth.Contract(poolAbi, poolAddress);
    let stakeTokenContract = new web3.eth.Contract(stakeTokenAbi, stakeTokenAddress);
    // let rewardTokenContract = new web3.eth.Contract(rewardTokenAbi, rewardTokenAddress);
    let userAddress = await getAccount();

    let snapPrice = await axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=snapex&vs_currencies=usd`);
    console.log(snapPrice);
    let snapPriceUSDT = snapPrice.data.snapex.usd;
    // let snapPriceUSDT = snapPrice.data.data.price;

    let stakeTokenDecimal = await getDecimals(stakeTokenAbi, stakeTokenAddress);
    let rewardTokenDecimal = await getDecimals(rewardTokenAbi, rewardTokenAddress);

    let totalLpSupplyRaw = await stakeTokenContract.methods.totalSupply().call();
    let totalLpSupply = totalLpSupplyRaw / 10 ** stakeTokenDecimal;

    let earned = userAddress
      ? await poolContract.methods
          .rewardBalanceOf(userAddress)
          .call()
          .then(tx => {
            if (tx > 0) {
              let fin = (tx / 10 ** stakeTokenDecimal).toString();
              if (fin.indexOf(".") > 0) {
                return fin.slice(0, fin.indexOf(".") + 7);
              } else {
                return fin;
              }
            } else {
              return "0";
            }
          })
      : 0;
    let totalPoolStacked = await poolContract.methods
      .totalpoolstacked()
      .call()
      .then(tx => {
        if (tx > 0) {
          return tx / 10 ** stakeTokenDecimal;
        } else {
          return 0;
        }
      });
    let rewardPerBlock = await poolContract.methods
      .rewardperblock()
      .call()
      .then(tx => {
        return tx / 10 ** stakeTokenDecimal;
      });
    //old apy
    // let apy = (
    //   (rewardPerBlock * 20 * 60 * 24 * 365 * currentTokenPriceUSDT * 100) /
    //   ((tvl / totalLpSupply) * 300000)
    // ).toFixed(6);

    //apy = (token earned in 1 year) / (vol / total supply)
    if (apyType == "lp") {
      //only for lp contract
      let snapInLpContractReserve = await stakeTokenContract.methods
        .getReserves()
        .call()
        .then(tx => {
          return tx[0] / 10 ** stakeTokenDecimal;
        });
      if (totalPoolStacked) {
        apy = (
          (rewardPerBlock * 20 * 60 * 24 * 365 * 100) /
          ((totalPoolStacked / totalLpSupply) * (snapInLpContractReserve * 2))
        ).toFixed(6);
        tvl = (totalPoolStacked / totalLpSupply) * (snapInLpContractReserve * 2) * snapPriceUSDT;
      }
    }
    if (apyType == "single") {
      //only for single snap
      if (totalPoolStacked) {
        apy = ((rewardPerBlock * 20 * 60 * 24 * 365 * 100) / totalPoolStacked).toFixed(6);
        tvl = totalPoolStacked * snapPriceUSDT;
      }
    }

    let myDeposit = userAddress
      ? await poolContract.methods
          .stakeBalanceOf(userAddress)
          .call()
          .then(tx => {
            if (tx > 0) {
              let fin = (tx / 10 ** stakeTokenDecimal).toString();
              if (fin.indexOf(".") > 0) {
                return fin.slice(0, fin.indexOf(".") + 7);
              } else {
                return fin;
              }
            } else {
              return "0";
            }
          })
      : 0;
    return { earned, apy, myDeposit, tvl };
  } catch (err) {
    console.log(err);
  }
};

export const checkApprovePool = async (stakeTokenAbi, stakeTokenAddress, poolAddress) => {
  try {
    let tokenContract = new web3.eth.Contract(stakeTokenAbi, stakeTokenAddress);
    let userAddress = await getAccount();
    let allowance = await tokenContract.methods.allowance(userAddress, poolAddress).call();
    if (Number(allowance) > 0) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    console.log(err);
  }
};

export const approvePool = (stakeTokenAbi, stakeTokenAddress, poolAddress) => async dispatch => {
  try {
    let tokenContract = new web3.eth.Contract(stakeTokenAbi, stakeTokenAddress);
    let userAddress = await getAccount();
    let res = await tokenContract.methods
      .approve(poolAddress, "1000000000000000000000000000000000000000000")
      .send({ from: userAddress });
    dispatch(success("Approved, txid: " + res.transactionHash));
    dispatch({
      type: RERENDER,
      payload: Math.random(),
    });
  } catch (err) {
    console.log(err);
    return metamaskErrorWrap(err, dispatch);
  }
};

export const stakePool = (stakeTokenAbi, stakeTokenAddress, poolAbi, poolAddress, stakeAmt) => async dispatch => {
  try {
    let poolContract = new web3.eth.Contract(poolAbi, poolAddress);
    let userAddress = await getAccount();
    let userBalance = await checkAccountBalance(stakeTokenAbi, stakeTokenAddress);
    if (Number(userBalance) < Number(stakeAmt)) {
      return dispatch(error("Insufficient balance"));
    }
    if (Number(stakeAmt) <= 0) {
      return dispatch(error("Invalid amount"));
    }
    let res = await poolContract.methods
      .stake((stakeAmt * 10 ** 18).toLocaleString("fullwide", { useGrouping: false }))
      .send({ from: userAddress });
    dispatch(success("Staked, txid: " + res.transactionHash));
    dispatch({
      type: RERENDER,
      payload: Math.random(),
    });
  } catch (err) {
    console.log(err);
    return metamaskErrorWrap(err, dispatch);
  }
};

export const claimPool = (poolAbi, poolAddress) => async dispatch => {
  try {
    let poolContract = new web3.eth.Contract(poolAbi, poolAddress);
    let userAddress = await getAccount();
    let res = await poolContract.methods.claim().send({ from: userAddress });
    dispatch(success("Claimed, txid: " + res.transactionHash));
    dispatch({
      type: RERENDER,
      payload: Math.random(),
    });
  } catch (err) {
    console.log(err);
    return metamaskErrorWrap(err, dispatch);
  }
};

export const getUnlockBlock = async (poolAbi, poolAddress) => {
  try {
    let poolContract = new web3.eth.Contract(poolAbi, poolAddress);
    let userAddress = await getAccount();
    let unlockBlock = await poolContract.methods._unlockblock(userAddress).call();
    if (unlockBlock === "0") {
      return 0;
    } else {
      return unlockBlock;
    }
    return unlockBlock;
  } catch (err) {
    console.log(err);
  }
};

export const unstakePool = (poolAbi, poolAddress, unstakeAmt) => async dispatch => {
  try {
    let poolContract = new web3.eth.Contract(poolAbi, poolAddress);
    let userAddress = await getAccount();
    let deposit = await poolContract.methods.stakeBalanceOf(userAddress).call();
    if (Number(deposit) < Number(unstakeAmt * 10 ** 18)) {
      return dispatch(error("Insufficient deposit"));
    }
    if (0 >= unstakeAmt) {
      return dispatch(error("Invalid amount"));
    }
    let res = await poolContract.methods
      .unstake((unstakeAmt * 10 ** 18).toLocaleString("fullwide", { useGrouping: false }))
      .send({ from: userAddress });
    dispatch(success("Claimed, txid: " + res.transactionHash));
    dispatch({
      type: RERENDER,
      payload: Math.random(),
    });
  } catch (err) {
    console.log(err);
    return metamaskErrorWrap(err, dispatch);
  }
};
