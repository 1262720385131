import { Typography } from "@material-ui/core";

function SproutieTab2() {
  return (
    <>
      <Typography variant="h5" color="textSecondary">
        Token Sale
      </Typography>
      <br />
      <table>
        <tbody>
          <tr>
            <td>
              <Typography variant="h6" color="textSecondary">
                Ticker
              </Typography>
            </td>
            <td>
              <Typography variant="h6" color="textSecondary">
                BEAN
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography variant="h6" color="textSecondary">
                Sale Price
              </Typography>
            </td>
            <td>
              <Typography variant="h6" color="textSecondary">
                $0.25
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography variant="h6" color="textSecondary">
                Sale Start Time (UTC)
              </Typography>
            </td>
            <td>
              <Typography variant="h6" color="textSecondary">
                -
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography variant="h6" color="textSecondary">
                Sale End Time (UTC)
              </Typography>
            </td>
            <td>
              <Typography variant="h6" color="textSecondary">
                -
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography variant="h6" color="textSecondary">
                Token Distribution (UTC)
              </Typography>
            </td>
            <td>
              <Typography variant="h6" color="textSecondary">
                -
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography variant="h6" color="textSecondary">
                Total Raise
              </Typography>
            </td>
            <td>
              <Typography variant="h6" color="textSecondary">
                $5,000
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography variant="h6" color="textSecondary">
                Initial Market Cap
              </Typography>
            </td>
            <td>
              <Typography variant="h6" color="textSecondary">
                $857,000
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography variant="h6" color="textSecondary">
                Initial Token Circulation
              </Typography>
            </td>
            <td>
              <Typography variant="h6" color="textSecondary">
                4,000,000
              </Typography>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <br />
      <br />
      <Typography variant="h5" color="textSecondary">
        Vesting Schedule
      </Typography>
      <br />
      <table>
        <tbody>
          <tr>
            <td>
              <Typography variant="h6" color="textSecondary">
                Expected Date
              </Typography>
            </td>
            <td>
              <Typography variant="h6" color="textSecondary">
                Percentage
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography variant="h6" color="textSecondary">
                TGE
              </Typography>
            </td>
            <td>
              <Typography variant="h6" color="textSecondary">
                30
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography variant="h6" color="textSecondary">
                2nd Month
              </Typography>
            </td>
            <td>
              <Typography variant="h6" color="textSecondary">
                30
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography variant="h6" color="textSecondary">
                3rd Month
              </Typography>
            </td>
            <td>
              <Typography variant="h6" color="textSecondary">
                40
              </Typography>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

export default SproutieTab2;
