import { useState } from "react";
import { Button, Fade, makeStyles, Modal, Paper, Typography, useMediaQuery } from "@material-ui/core";
import snaplogo from "src/assets/images/snapex-logo.png";
const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: "10px",
  },
}));

function RuleMenu({ theme }) {
  const classes = useStyles();
  const largeScreen = useMediaQuery("(min-width: 1000px");
  const smallerScreen = useMediaQuery("(max-width: 650px)");
  const verySmallScreen = useMediaQuery("(max-width: 379px)");

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div>
        <Button className="snap-rules" variant="contained" color="secondary" size="small" onClick={() => setOpen(true)}>
          Rules
        </Button>
      </div>
      <Modal
        id="snap-rules-modal"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        aria-labelledby="Snap Rules"
        aria-describedby="Snap Rules"
      >
        <Fade in={open}>
          <Paper
            className={classes.paper + " snap-rule-content"}
            style={smallerScreen ? { width: "100%" } : largeScreen ? { width: "800px" } : ""}
          >
            <div className="modal-header">
              <img src={snaplogo} alt="Snap Logo" style={{ height: "1.5em", width: "1.5em" }} />
              <Typography variant="h4">
                <b>Staking Rules</b>
              </Typography>
            </div>
            <hr />
            <div className="modal-body">
              <Typography variant="h6" className="snap-address">
                SNAP Token Address: 0x57cd5a91c18d21dedd72d3bcf255b60aef290f53
              </Typography>
              <br />
              <br />
              <Typography variant="h6">Staking Start Data: 27/01/2021</Typography>
              <br />
              <br />
              <Typography variant="h6">Yearly Distribution: 6M</Typography>
              <br />
              <br />
              <Typography variant="h6">Minimun Staking: 0.0001 SNAP</Typography>
              <br />
              <br />
              <Typography variant="h6">Staking Lock Up Period: 0 / 45 / 90 days</Typography>
              <br />
              <br />
              <Typography variant="h6">SNAP Liquidity and Staking Program</Typography>
              <br />
              <br />
              <br />
              <br />
              <div>
                *Earn the highest staking rewards during the first month. It is delighted to offer the highest-yield
                staking returns during the first months of the SnapStake function. Returns of up to 16% will be
                available during the first month
              </div>
            </div>
          </Paper>
        </Fade>
      </Modal>
    </>
  );
}

export default RuleMenu;
