import {
  USER_ADDRESS,
  SINGLE_STACK_PROGRESS,
  CHECK_WHITELIST,
  CHECK_APPROVE,
  CHECK_USER_INVEST_STATUS,
  RERENDER,
} from "./launchpadActions";

const initialState = {
  userAddress: "",
  singleStakeProgress: 0,
  whitelistAmt: 0,
  approveStatus: true, // TODO: Prod false
  availableInvestment: 0,
  rerender: 0,
};

export function launchpadReducer(state = initialState, action) {
  switch (action.type) {
    case USER_ADDRESS:
      return { ...state, userAddress: action.payload };
    case SINGLE_STACK_PROGRESS:
      return { ...state, singleStakeProgress: action.payload };
    case CHECK_WHITELIST:
      return { ...state, whitelistAmt: action.payload };
    case CHECK_APPROVE:
      return { ...state, approveStatus: action.payload };
    case CHECK_USER_INVEST_STATUS:
      return { ...state, availableInvestment: action.payload };
    case RERENDER:
      return { ...state, rerender: action.payload };
    default:
      return state;
  }
}
