import axios from "axios";
import Web3 from "web3";
const web3 = new Web3(window.ethereum);
import { tokenHolderAddress, chainId } from "src/constants";
import { abi as snapAbi } from "src/abi/Dashboard/Snap.json";
import { error, info, success } from "src/slices/MessagesSlice";
import { switchNetwork } from "src/helpers/SwitchNetwork";
import allPools from "src/helpers/AllPools";
import { getDecimals } from "src/helpers/SnapStaking";

export const USDT_PRICE = "USDT_PRICE";
export const SNAP_SUPPLY = "SNAP_SUPPLY";
export const SNAP_BURN = "SNAP_BURN";
export const CIRC_SUPPLY = "CIRC_SUPPLY";
export const SNAP_TOTAL_LP_STAKED = "SNAP_TOTAL_LP_STAKED";
export const SNAP_DIST = "SNAP_DIST";
export const POOL_TVL = "POOL_TVL";
export const TOTAL_STAKED_ASSET = "TOTAL_STAKED_ASSET";

export const checkNetwork = async networkName => {
  try {
    if (window.ethereum) {
      const network = await window.ethereum.request({
        method: "net_version",
      });
      if (network !== chainId[networkName]) {
        switchNetwork(chainId[networkName]);
      }
    }
  } catch (err) {
    console.log(err);
  }
};
export const getCurrentUSDTPrice = () => async dispatch => {
  try {
    let price = await axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=snapex&vs_currencies=usd
    `);
    if (price) {
      price = Number(price.data.snapex.usd);
      dispatch({
        type: USDT_PRICE,
        payload: price,
      });
      return price;
    }
  } catch (err) {
    console.log(err);
  }
};

export const getCurrentBlock = async () => {
  try {
    checkNetwork("BINANCE_MAINNET");
    let currentBlock = await web3.eth.getBlockNumber();
    return currentBlock;
  } catch (err) {
    console.log(err);
  }
};

export const getTotalSnapSupply = () => async dispatch => {
  try {
    let sendToDiffAddr = 3805779.97802 - 1788292.97802; // ask alan/jer
    checkNetwork("BINANCE_MAINNET");
    let tokenContract = new web3.eth.Contract(snapAbi, tokenHolderAddress.SNAP_ADDRESS);
    let totalSnapSupply = await tokenContract.methods.totalSupply().call();
    if (totalSnapSupply) {
      totalSnapSupply = totalSnapSupply / 10 ** 18 - sendToDiffAddr;
      dispatch({
        type: SNAP_SUPPLY,
        payload: totalSnapSupply,
      });
    }
    return totalSnapSupply;
  } catch (err) {
    console.log(err);
  }
};

export const getCirculatingSupply = () => async dispatch => {
  try {
    let tokenContract = new web3.eth.Contract(snapAbi, tokenHolderAddress.SNAP_ADDRESS);
    let circSupply = await tokenContract.methods.getCirculatingSupply().call();
    if (circSupply) {
      circSupply = circSupply / 10 ** 18;
      dispatch({
        type: CIRC_SUPPLY,
        payload: circSupply,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getSnapBurn = () => async dispatch => {
  try {
    checkNetwork("BINANCE_MAINNET");
    let tokenContract = new web3.eth.Contract(snapAbi, tokenHolderAddress.SNAP_ADDRESS);
    // TODO: Replace with real methods
    // let snapBurn = await tokenContract.methods.burn().call();
    // if (snapBurn) {
    //   dispatch({
    //     type: SNAP_BURN,
    //     payload: snapBurn / 10 ** 18,
    //   });
    // }
    // return 1788292.97802;
  } catch (err) {
    console.log(err);
  }
};

export const getTotalLpStaked = () => async dispatch => {
  try {
    checkNetwork("BINANCE_MAINNET");
    let tokenContract = new web3.eth.Contract(snapAbi, tokenHolderAddress.SNAP_ADDRESS);
    // TODO: Replace with real methods
    // let totalLpStaked = await tokenContract.methods.totalPoolStaked().call();
    // if (totalLpStaked) {
    //   dispatch({
    //     type: SNAP_TOTAL_LP_STAKED,
    //     payload: totalLpStaked / 10 ** 18,
    //   });
    // }
  } catch (err) {
    console.log(err);
  }
};

export const getDistribution = () => async dispatch => {
  try {
    checkNetwork("BINANCE_MAINNET");
    let tokenContract = new web3.eth.Contract(snapAbi, tokenHolderAddress.SNAP_ADDRESS);
    let deployerRaw = await tokenContract.methods.balanceOf(tokenHolderAddress.DEPLOYER).call();
    let snapexRaw = await tokenContract.methods.balanceOf(tokenHolderAddress.SNAPEX).call();
    let launchpadRaw = await tokenContract.methods.balanceOf(tokenHolderAddress.LAUNCHPAD).call();
    let pancakeRaw = await tokenContract.methods.balanceOf(tokenHolderAddress.PANCAKE).call();

    dispatch({
      type: SNAP_DIST,
      payload: {
        deployer: deployerRaw / 10 ** 18,
        snapex: snapexRaw / 10 ** 18,
        launchpad: launchpadRaw / 10 ** 18,
        pancake: pancakeRaw / 10 ** 18,
      },
    });
    return {
      deployer: deployerRaw / 10 ** 18,
      snapex: snapexRaw / 10 ** 18,
      launchpad: launchpadRaw / 10 ** 18,
      pancake: pancakeRaw / 10 ** 18,
    };
  } catch (err) {
    console.log(err);
  }
};

export const getTvl = async (stakeTokenAbi, stakeTokenAddress, poolAbi, poolAddress, apyType) => {
  let poolContract = new web3.eth.Contract(poolAbi, poolAddress);
  let stakeTokenContract = new web3.eth.Contract(stakeTokenAbi, stakeTokenAddress);
  let stakeTokenDecimal = await getDecimals(stakeTokenAbi, stakeTokenAddress);

  let totalLpSupplyRaw = await stakeTokenContract.methods.totalSupply().call();
  let totalLpSupply = totalLpSupplyRaw / 10 ** stakeTokenDecimal;

  let snapPrice = await axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=snapex&vs_currencies=usd`);
  console.log(snapPrice.data.snapex.usd);
  let snapPriceUSDT = snapPrice.data.snapex.usd;

  let tvl = 0;

  // Get total pool stacked
  let totalPoolStacked = await poolContract.methods
    .totalpoolstacked()
    .call()
    .then(tx => {
      if (tx > 0) {
        return tx / 10 ** stakeTokenDecimal;
      } else {
        return 0;
      }
    });
  // Compute Tvl
  if (apyType == "lp") {
    let snapInLpContractReserve = await stakeTokenContract.methods
      .getReserves()
      .call()
      .then(tx => {
        return tx[0] / 10 ** stakeTokenDecimal;
      });
    if (totalPoolStacked) {
      tvl = (totalPoolStacked / totalLpSupply) * (snapInLpContractReserve * 2) * snapPriceUSDT;
    }
  }
  if (apyType == "single") {
    if (totalPoolStacked) {
      tvl = totalPoolStacked * snapPriceUSDT;
    }
  }
  return tvl;
};

export const getTotalStakedAssetUSDT = () => async dispatch => {
  try {
    let tvlArr = await Promise.all(
      allPools.map((el, i) => {
        let res = getTvl(el.stakeTokenAbi, el.stakeTokenAddress, el.abi, el.address, el.apyType);
        return res;
      }),
    );

    let totalStakedAsset = tvlArr.reduce((prev, curr) => prev + curr, 0);

    // console.log("tvlArray: ", tvlArr);
    // console.log("totalAsset: ", totalStakedAsset);
    dispatch({
      type: POOL_TVL,
      payload: tvlArr,
    });
    dispatch({
      type: TOTAL_STAKED_ASSET,
      payload: totalStakedAsset,
    });
    return tvlArr;
  } catch (err) {
    console.log(err);
  }
};

export const initAppData = () => async dispatch => {
  await getCurrentUSDTPrice();
  await getTotalSnapSupply();
  await getDistribution();
  await getTotalStakedAssetUSDT();
};
