import { CircularProgress, makeStyles, useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  circle: {
    stroke: "url(#linearColors)",
    position: "absolute",
    right: 0,
    zIndex: 2,
  },
}));

function StakingDistribution({ percentage, icon }) {
  const classes = useStyles({});
  const smallerScreen = useMediaQuery("(max-width: 650px)");

  return (
    <>
      <div className="circular-progress-wrapper">
        <CircularProgress variant="determinate" thickness={1.5} size={"90px"} className={"background"} value={100} />

        <CircularProgress
          variant="determinate"
          thickness={1.5}
          size={"90px"}
          classes={{ circle: classes.circle }}
          // className={"foreground"}
          value={Number(percentage)}
        />
        <svg width="90" height="90" style={{ position: "absolute" }}>
          <linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="1">
            <stop offset="20%" stopColor="#F3F" />
            <stop offset="100%" stopColor="#F93" />
          </linearGradient>
        </svg>

        <div className="pool-icon">
          <img src={icon} />
        </div>
      </div>
    </>
  );
}

export default StakingDistribution;
