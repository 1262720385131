import { useState, useEffect } from "react";
import { addresses, TOKEN_DECIMALS } from "../../constants";
import { Link, SvgIcon, Popper, Button, Paper, Typography, Divider, Box, Fade, Slide } from "@material-ui/core";
import { ReactComponent as InfoIcon } from "../../assets/icons/info-fill.svg";
import { ReactComponent as ArrowUpIcon } from "../../assets/icons/arrow-up.svg";
import { ReactComponent as sSnapTokenImg } from "../../assets/tokens/SNAP-token.svg";
import { ReactComponent as snapTokenImg } from "../../assets/tokens/sSNAP-token.svg";
import { ReactComponent as wsSnapTokenImg } from "../../assets/tokens/wsSNAP-token.svg";
import "./papamenu.scss";
import { useWeb3Context } from "../../hooks/web3Context";
import SNAPImg from "src/assets/tokens/SNAP.png";
import sSNAPImg from "src/assets/tokens/sSNAP.png";
import wsSNAPImg from "src/assets/tokens/wsSNAP.png";
import { NavLink } from "react-router-dom";

const addTokenToWallet = (tokenSymbol, tokenAddress) => async () => {
  if (window.ethereum) {
    const host = window.location.origin;
    // NOTE (appleseed): 33T token defaults to sPAPA logo since we don't have a 33T logo yet
    let tokenPath, decimals;
    switch (tokenSymbol) {
      case "SNAP":
        tokenPath = SNAPImg;
        decimals = 18;
        break;
      case "SNAP-BNB-LP":
        tokenPath = sSNAPImg;
        decimals = 18;
        break;
      default:
        tokenPath = SNAPImg;
    }
    const imageURL = `${host}/${tokenPath}`;

    try {
      await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: TOKEN_DECIMALS,
            image: imageURL,
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  }
};

function PapaMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const isEthereumAPIAvailable = window.ethereum;
  const { chainID } = useWeb3Context();

  const networkID = chainID;

  const SNAP_ADDRESS = addresses[networkID].SNAP_ADDRESS;
  const SNAP_LP_ADDRESS = addresses[networkID].SNAP_LP_ADDRESS;

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = "papa-popper";
  return (
    <Box
      component="div"
      onMouseEnter={e => handleClick(e)}
      onMouseLeave={e => handleClick(e)}
      id="papa-menu-button-hover"
    >
      <Button
        id="papa-menu-button"
        size="small"
        variant="contained"
        color="secondary"
        title="SNAP"
        aria-describedby={id}
      >
        {/* <SvgIcon component={InfoIcon} color="primary" /> */}
        <Typography>SNAP</Typography>
      </Button>

      <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-start" transition>
        {({ TransitionProps }) => {
          return (
            <Fade {...TransitionProps} timeout={100}>
              <Paper className="papa-menu" elevation={1}>
                {/* <Box component="div" className="buy-tokens">
                  <Link
                    href={`https://traderjoexyz.com/trade?inputCurrency=0x130966628846BFd36ff31a822705796e8cb8C18D&outputCurrency=0x70b33ebC5544C12691d055b49762D0F8365d99Fe#/`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button size="large" variant="contained" color="secondary" fullWidth>
                      <Typography align="left">
                        Buy on Trader Joe <SvgIcon component={ArrowUpIcon} htmlColor="#A3A3A3" />
                      </Typography>
                    </Button>
                  </Link>

                  <Link component={NavLink} to="/wrap" style={{ textDecoration: "none" }}>
                    <Button size="large" variant="contained" color="secondary" fullWidth>
                      <Typography align="left">Wrap sDREX</Typography>
                    </Button>
                  </Link>
                </Box> */}

                {isEthereumAPIAvailable ? (
                  <Box className="add-tokens">
                    {/* <Divider color="secondary" /> */}
                    <p>ADD TOKEN TO WALLET</p>
                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                      <Button variant="contained" color="secondary" onClick={addTokenToWallet("SNAP", SNAP_ADDRESS)}>
                        <img src={SNAPImg} viewBox="0 0 100 100" style={{ height: "25px", width: "25px" }} />
                        {/* <SvgIcon
                          component={snapTokenImg}
                          viewBox="0 0 100 100"
                          style={{ height: "25px", width: "25px" }}
                        /> */}
                        <Typography variant="body1">SNAP</Typography>
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={addTokenToWallet("SNAP-BNB-LP", SNAP_LP_ADDRESS)}
                      >
                        <img src={sSNAPImg} viewBox="0 0 100 100" style={{ height: "25px", width: "25px" }} />
                        {/* <SvgIcon
                          component={sSnapTokenImg}
                          viewBox="0 0 100 100"
                          style={{ height: "25px", width: "25px" }}
                        /> */}
                        <Typography variant="body1">SNAP-BNB LP</Typography>
                      </Button>
                      {/* <Button
                        variant="contained"
                        color="secondary"
                        onClick={addTokenToWallet("wsSNAP", WSSNAP_ADDRESS)}
                      >
                        <SvgIcon
                          component={wsSnapTokenImg}
                          viewBox="0 0 100 100"
                          style={{ height: "25px", width: "25px" }}
                        />
                        <Typography variant="body1">wsSNAP</Typography>
                      </Button> */}
                    </Box>
                  </Box>
                ) : null}
              </Paper>
            </Fade>
          );
        }}
      </Popper>
    </Box>
  );
}

export default PapaMenu;
