import { abi as BSC_USDT_Abi } from "src/abi/launchpad/strtButton/bscUSDT.json";
import { abi as STRT_STAKING_Abi } from "src/abi/launchpad/strtButton/strtStaking.json";
import strtBanner from "../assets/launchpad/strt/strtBanner.jpeg";
import strtIcon from "../assets/launchpad/strt/strtIcon.png";
import sproutieBanner from "../assets/launchpad/sproutieBanner.png";
import sproutieLogo from "../assets/launchpad/sproutieLogo.jpeg";
import creoBanner from "../assets/launchpad/creoBanner.jpg";
import creoLogo from "../assets/launchpad/creoLogo.png";

/** Import social media icons */
import { ReactComponent as GitHub } from "src/assets/icons/github-colored.svg";
import { ReactComponent as Medium } from "src/assets/icons/medium-colored.svg";
import { ReactComponent as Twitter } from "src/assets/icons/twitter-colored.svg";
import { ReactComponent as Discord } from "src/assets/icons/discord-colored.svg";
import { ReactComponent as Telegram } from "src/assets/icons/telegram-colored.svg";
import { ReactComponent as Facebook } from "src/assets/icons/facebook-colored.svg";
import { ReactComponent as Youtube } from "src/assets/icons/youtube-colored.svg";
import { ReactComponent as Linkedin } from "src/assets/icons/linkedin-colored.svg";
import { ReactComponent as Instagram } from "src/assets/icons/instagram-colored.svg";
import { ReactComponent as Email } from "src/assets/icons/email-colored.svg";

export const strtButton = {
  name: "STRT BUTTON", // Project name
  subtitle: "Decentralized Token Management Platform",
  banner: strtBanner,
  logo: strtIcon,
  whitelistUrl: "https://www.snapex.com/",
  websiteName: "strtbutton.com",
  websiteUrl: "https://strtbutton.com/",
  socialMedia: [
    { logo: Twitter, url: "https://twitter.com/strtbutton" },
    { logo: Medium, url: "https://strtbutton.medium.com/" },
    { logo: Telegram, url: "https://strtbuttonglobal.medium.com/" },
  ],
  tabs: ["Description", "Token Sale"], // Customize pitch content tab
  endDate: "March 18 2022 21:00:00 GMT+0800", // Date to START allowing investment
  finDate: "March 19 2022 21:00:00 GMT+0800", // Date to STOP allowing investment
  pricePerToken: "0.06",
  target: 25000,
  tokenSymbol: "USDT", // Use which token to invest
  tokenAbi: BSC_USDT_Abi,
  tokenAddress: "0x55d398326f99059fF775485246999027B3197955", // BSC USDT
  projectAbi: STRT_STAKING_Abi,
  projectAddress: "0x63f283Dc359578FACE8Ba2Fa134AE86b782656b7", // STRT Staking
  allocation: "Public Round",
  linkName: "strtbutton",
  ended: true,
  // raised: 12532.72, // Optional
};

// export const sproutie = {
//   name: "SPROUTIE", // Project name
//   subtitle: "World’s first Metaverse NFT based game that encompasses Real World Economics",
//   banner: sproutieBanner,
//   logo: sproutieLogo,
//   whitelistUrl: "", //TODO: Update this
//   websiteName: "sproutie.io",
//   websiteUrl: "https://sproutie.io/",
//   socialMedia: [
//     { logo: Twitter, url: "https://twitter.com/sproutienftgame" },
//     { logo: Telegram, url: "https://t.me/SPROUTIEofficial" },
//     { logo: Linkedin, url: "https://www.linkedin.com/company/sproutie-nft-game" },
//     { logo: Discord, url: "https://discord.gg/7v3SufbS2c" },
//   ],
//   tabs: ["Description", "Token Sale"], // Customize pitch content tab
//   endDate: "TBC", // Date to START allowing investment
//   finDate: "TBC", // Date to STOP allowing investment
//   pricePerToken: 0.25,
//   target: 5000,
//   tokenSymbol: "USDT", // Use which token to invest
//   tokenAbi: BSC_USDT_Abi,
//   tokenAddress: "0x55d398326f99059fF775485246999027B3197955", // BSC USDT
//   projectAbi: STRT_STAKING_Abi, //TODO: Update this
//   projectAddress: "", //TODO: Update this
//   allocation: "Public Round",
//   linkName: "sproutie",
//   ended: false,
// };

export const creo = {
  name: "CREO", // Project name
  subtitle: "High Quality Gamefi with Interchangeable Assets/NFTs all under one healthy ecosystem.",
  banner: creoBanner,
  logo: creoLogo,
  whitelistUrl: "https://bit.ly/3vpVJuB", //TODO: Update this
  websiteName: "creoengine.com",
  websiteUrl: "https://creoengine.com",
  socialMedia: [
    { logo: Twitter, url: "https://twitter.com/creo_engine" },
    { logo: Telegram, url: "https://t.me/creoenginechannel" },
    { logo: Instagram, url: "https://instagram.com/creoengine.official" },
    { logo: Medium, url: "https://creoengineofficial.medium.com" },
    { logo: Facebook, url: "https://facebook.com/CreoEngineGlobal" },
    { logo: Youtube, url: "https://youtube.com/CreoEngineOfficial" },
    { logo: Email, url: "contact@creoengine.com" },
  ],
  tabs: ["Description", "Token Sale"], // Customize pitch content tab
  endDate: "April 25 2022 21:00:00 GMT+0800", // Date to START allowing investment
  finDate: "April 25 2022 23:00:00 GMT+0800", // Date to STOP allowing investment
  pricePerToken: 0.04,
  target: 5000,
  tokenSymbol: "USDT", // Use which token to invest
  tokenAbi: BSC_USDT_Abi,
  tokenAddress: "0x55d398326f99059fF775485246999027B3197955", // BSC USDT
  projectAbi: STRT_STAKING_Abi, //TODO: Update this
  projectAddress: "0x122fA2DbFb44acE4d6c17Ad454387d468670d158", //TODO: Update this
  allocation: "Public Round",
  publicRound: true,
  linkName: "creo",
  ended: true,
};

// export const test = {
//   name: "TEST", // Project name
//   subtitle: "Testing project. 123 123",
//   banner: sproutieBanner,
//   logo: sproutieLogo,
//   whitelistUrl: "https://bit.ly/3vpVJuB", //TODO: Update this
//   websiteName: "creoengine.com",
//   websiteUrl: "https://creoengine.com",
//   socialMedia: [
//     { logo: Twitter, url: "https://twitter.com/creo_engine" },
//     { logo: Telegram, url: "https://t.me/creoenginechannel" },
//     { logo: Instagram, url: "https://instagram.com/creoengine.official" },
//   ],
//   tabs: ["Description", "Token Sale"], // Customize pitch content tab
//   endDate: "May 3 2022 21:00:00 GMT+0800", // Date to START allowing investment / "TBC"
//   finDate: "May 4 2022 21:00:00 GMT+0800", // Date to STOP allowing investment / "TBC"
//   pricePerToken: 0.04,
//   target: 6000,
//   tokenSymbol: "USDT", // Use which token to invest
//   tokenAbi: BSC_USDT_Abi,
//   tokenAddress: "0x55d398326f99059fF775485246999027B3197955", // BSC USDT
//   projectAbi: STRT_STAKING_Abi, //TODO: Update this
//   projectAddress: "0x122fA2DbFb44acE4d6c17Ad454387d468670d158", //TODO: Update this
//   publicRound: false,
//   linkName: "test",
// };

/** Note:
 * To add a new launchpad project:
 * 1. Create a new project object, note that property "ended" and "raised" is NOT compulsory
 *    > If "ended" = true, project will not appear in upcoming section
 *    > If "raised" has a value, progress bar will display it instead of calling from web3
 * 2. Add the project into the allLaunchpads array
 * 3. Go to /src/redux/launchpadActions.js
 *    > Import the whilelist csv file & add into csvArr
 *    > The csv array order MUST follow the launchpad array order
 */

export const allLaunchpads = [strtButton, creo]; // <= Add your new project here
export const allLaunchpadsMap = allLaunchpads.reduce((prev, launchpad) => {
  return { ...prev, [launchpad.name]: launchpad };
});
