import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Grid } from "@material-ui/core";

function PoolInfo({ pool }) {
  const dispatch = useDispatch();

  const handleLink = key => {
    window.open("https://bscscan.com/address/" + pool[key]);
  };

  return (
    <>
      <div className="pool-info">
        <Grid container>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <div>
              <b>Stake Token Address</b>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <div onClick={() => handleLink("stakeTokenAddress")} className="scan-link">
              {pool.stakeTokenAddress}
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <div>
              <b>Staking Contract Address</b>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <div onClick={() => handleLink("address")} className="scan-link">
              {pool.address}
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default PoolInfo;
