import {
  USDT_PRICE,
  SNAP_SUPPLY,
  SNAP_BURN,
  CIRC_SUPPLY,
  SNAP_TOTAL_LP_STAKED,
  SNAP_DIST,
  POOL_TVL,
  TOTAL_STAKED_ASSET,
} from "./dashboardActions";

const initialState = {
  currentUSDTPrice: "",
  totalSnapSupply: "",
  circSnapSupply: "",
  snapBurn: "",
  snapTotalLpStaked: "",
  snapDistribution: {},
  tvlArray: [],
  totalStakedAsset: 0,
};

export function snapDashboardReducer(state = initialState, action) {
  switch (action.type) {
    case USDT_PRICE:
      return { ...state, currentUSDTPrice: action.payload };
    case SNAP_SUPPLY:
      return { ...state, totalSnapSupply: action.payload };
    case CIRC_SUPPLY:
      return { ...state, circSnapSupply: action.payload };
    case SNAP_BURN:
      return { ...state, snapBurn: action.payload };
    case SNAP_TOTAL_LP_STAKED:
      return { ...state, snapTotalLpStaked: action.payload };
    case SNAP_DIST:
      return { ...state, snapDistribution: action.payload };
    case POOL_TVL:
      return { ...state, tvlArray: action.payload };
    case TOTAL_STAKED_ASSET:
      return { ...state, totalStakedAsset: action.payload };
    default:
      return state;
  }
}
