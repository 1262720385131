import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, TextField, Typography } from "@material-ui/core";
import { getCurrentBlock } from "src/redux/snapdashboard/dashboardActions";
import { claimPool, unstakePool } from "src/helpers/SnapStaking";
import { error, info, success } from "src/slices/MessagesSlice";

function Withdraw({ pool, poolFigure, unlockBlock }) {
  const dispatch = useDispatch();
  const [unstakeAmt, setUnstakeAmt] = useState(0);
  const [currentBlock, setCurrentBlock] = useState("");

  const handleClaim = () => {
    if (!poolFigure.earned || poolFigure.earned <= 0) {
      return;
    } else {
      dispatch(claimPool(pool.abi, pool.address));
    }
  };

  const handleUnstake = () => {
    if (!/^\d*\.?\d*$/.test(unstakeAmt) || unstakeAmt <= 0) {
      dispatch(error("Please enter a valid amount"));
    } else {
      dispatch(unstakePool(pool.abi, pool.address, unstakeAmt));
    }
  };

  useEffect(() => {
    let active = true;
    init();
    return () => {
      active = false;
    };
    async function init() {
      let currentBlock = await getCurrentBlock();
      if (!active) return;
      setCurrentBlock(currentBlock);
    }
  }, []);

  return (
    <>
      <div className="withdraw">
        <div className="available">Available for claiming: {poolFigure ? poolFigure.earned : "-"}</div>
        <div className="withdraw-action">
          <TextField
            label={"Amount of " + pool.rewardToken + " to claim"}
            variant="outlined"
            color="primary"
            value={poolFigure ? poolFigure.earned : "-"}
            disabled
          />
          <div className="withdraw-step">
            <Button
              variant="outlined"
              disabled={!poolFigure || !poolFigure.earned || poolFigure.earned <= 0}
              onClick={handleClaim}
            >
              <div>Claim</div>
            </Button>
          </div>
        </div>
        <div className="available">Available for unstaking: {poolFigure ? poolFigure.myDeposit : "-"}</div>
        {/* {poolFigure.myDeposit && poolFigure.myDeposit !== "0" && ( */}
        {/* )} */}
        <div className="withdraw-action">
          <TextField
            label={"Amount of " + pool.stakeToken + " to unstake"}
            variant="outlined"
            size="small"
            color="primary"
            defaultValue="0"
            type="Number"
            min="0"
            onChange={e => setUnstakeAmt(e.currentTarget.value)}
          />
          <div className="withdraw-step">
            <Button
              variant="outlined"
              onClick={handleUnstake}
              disabled={
                !poolFigure ||
                !poolFigure.myDeposit ||
                poolFigure.myDeposit <= 0 ||
                currentBlock === 0 ||
                (currentBlock < unlockBlock && pool.disabled !== 1)
              }
            >
              <div>{unlockBlock !== 0 && currentBlock < unlockBlock && pool.disabled !== 1 ? `Locked` : "Unstake"}</div>
            </Button>
          </div>
        </div>
        {currentBlock < unlockBlock && (
          <>
            <div className="available">Unlock block: {unlockBlock ? unlockBlock : "-"}</div>
            <div className="available">Current block: {currentBlock ? currentBlock : "-"}</div>
          </>
        )}
      </div>
    </>
  );
}

export default Withdraw;
