import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Link, Typography, Zoom } from "@material-ui/core";
import LaunchpadTimer from "../../components/LaunchpadTimer";
import LaunchpadProgress from "./LaunchpadProgress";
import "./launchpad.scss";
import { allLaunchpads } from "src/helpers/AllLaunchpad";
import { isCountingDown, isActive, timerStatusHandler } from "src/helpers/ProjectStatusHandler";

function Launchpad() {
  const [zoomed, setZoomed] = useState(false);

  return (
    <>
      <div className="launchpad">
        <Typography variant="h3">Upcoming Projects</Typography>
        <div className="launchpad-view">
          {allLaunchpads && allLaunchpads.length > 0
            ? allLaunchpads
                .filter((pj, i) => isActive(pj))
                .map((project, i) => (
                  <Zoom in={true} key={i} onEntered={() => setZoomed(true)}>
                    <Paper className="launchpad-card">
                      <Link component={NavLink} id="launchpad-detail" to={"/" + project.linkName}>
                        <div className="card-banner">
                          <div className="card-img-top">
                            <img
                              src={project.banner}
                              alt="Project Banner"
                              style={{ minWidth: "290px", minHeight: "155px", width: "290px" }}
                            />
                          </div>
                          <div className="card-logo">
                            <img src={project.logo} alt="" />
                          </div>
                        </div>

                        <div className="launchpad-countdown">
                          <Typography color="textSecondary">
                            <span>IDO starts in : </span>
                            <span>
                              {timerStatusHandler(project) ? (
                                timerStatusHandler(project)
                              ) : (
                                <LaunchpadTimer endDate={project.endDate} />
                              )}
                            </span>
                          </Typography>
                        </div>
                        <div className="launchpad-header">
                          <Typography variant="h3" color="textPrimary">
                            <b>{project.name}</b>
                          </Typography>
                          <div className="project-subtitle">{project.subtitle}</div>
                        </div>

                        <div className="launchpad-raise">
                          <Typography variant="h6" color="textSecondary">
                            Total Raise:
                          </Typography>
                          <LaunchpadProgress project={project} />
                          {project.allocation ? (
                            <Typography color="textSecondary" className="personal-allocation">
                              Remaining Allocation : <span>{project.allocation}</span>
                            </Typography>
                          ) : (
                            <br />
                          )}
                        </div>
                      </Link>
                    </Paper>
                  </Zoom>
                ))
            : null}
        </div>

        <Typography variant="h3" style={{ marginTop: "1em" }}>
          Ended Projects
        </Typography>
        <div className="launchpad-view">
          {allLaunchpads && allLaunchpads.length > 0
            ? allLaunchpads
                .filter((pj, i) => !isActive(pj))
                .map((project, i) => (
                  <Zoom in={true} key={i} onEntered={() => setZoomed(true)}>
                    <Paper className="launchpad-card">
                      <Link component={NavLink} id="launchpad-detail" to={"/" + project.linkName}>
                        <div className="card-banner">
                          <div className="card-img-top">
                            <img
                              src={project.banner}
                              alt="Project Banner"
                              style={{ minWidth: "290px", minHeight: "155px", width: "290px" }}
                            />
                          </div>
                          <div className="card-logo">
                            <img src={project.logo} alt="" />
                          </div>
                        </div>

                        <div className="launchpad-countdown">
                          <Typography color="textSecondary">
                            <span>IDO starts in : </span>
                            <span>Ended</span>
                          </Typography>
                        </div>
                        <div className="launchpad-header">
                          <Typography variant="h3" color="textPrimary">
                            <b>{project.name}</b>
                          </Typography>
                          <div className="project-subtitle">{project.subtitle}</div>
                        </div>

                        <div className="launchpad-raise" style={{ visibility: "hidden" }}>
                          <Typography variant="h6" color="textSecondary">
                            Total Raise:
                          </Typography>
                          <LaunchpadProgress project={project} />
                          <br />
                        </div>
                      </Link>
                    </Paper>
                  </Zoom>
                ))
            : null}
        </div>
      </div>
    </>
  );
}

export default Launchpad;
