import LaunchpadSocial from "./LaunchpadSocial";

/** Project1: STRT Button */
import StrtButtonTab1 from "./Pitch/StrtButton/StrtButtonTab1";
import StrtButtonTab2 from "./Pitch/StrtButton/StrtButtonTab2";
/** Project2: Sproutie */
import SproutieTab1 from "./Pitch/Sproutie/SproutieTab1";
import SproutieTab2 from "./Pitch/Sproutie/SproutieTab2";
/** Project3: Creo */
import CreoTab1 from "./Pitch/Creo/CreoTab1";
import CreoTab2 from "./Pitch/Creo/CreoTab2";

function LaunchpadPitch(props) {
  return (
    <>
      {/** Project1: STRT Button */}
      <div hidden={props.pj !== 1}>
        <div hidden={props.tabNumber !== 1}>
          <StrtButtonTab1 />
          <LaunchpadSocial pidx={props.pidx} />
        </div>
        <div hidden={props.tabNumber !== 2}>
          <StrtButtonTab2 />
        </div>
      </div>
      {/** Project2: Creo */}
      <div hidden={props.pj !== 2}>
        <div hidden={props.tabNumber !== 1}>
          <CreoTab1 />
          <LaunchpadSocial pidx={props.pidx} />
        </div>
        <div hidden={props.tabNumber !== 2}>
          <CreoTab2 />
        </div>
      </div>
      {/** Project3: Sproutie */}
      <div hidden={props.pj !== 3}>
        <div hidden={props.tabNumber !== 1}>
          <SproutieTab1 />
          <LaunchpadSocial pidx={props.pidx} />
        </div>
        <div hidden={props.tabNumber !== 2}>
          <SproutieTab2 />
        </div>
      </div>
    </>
  );
}

export default LaunchpadPitch;
