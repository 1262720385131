/**
 * +-------------------------------------------------+
 * | ended => Ended
 * | !whitelistUrl
 * |   endDate => Coming Soon
 * |   !endDate => TBC
 * | whitelistUrl
 * |   !isStarted + !whitelistAmt => Whitelist
 * |   !isStarted + whitelistAmt"
 * |     isActive + haveContract:
 * |       isApproved => Invest
 * |       !isApproved => Approve
 * |     /isActive + !haveContract => Coming Soon
 * |     \!isActive: Coming Soon
 * | publicRound
 * |   isStarted + haveCotract:
 * |     isApproved => Invest
 * |     !isApproved => Approve
 * |   !isStarted => Coming Soon
 * | !publicRound + !isCountingDown(finDate) => Ended
 * +
 */

export const isCountingDown = date => {
  let finDate = new Date(date).getTime();
  let now = new Date().getTime();
  return finDate >= now; // return false if time out
};

export const isActive = pj => {
  if (pj.ended) return false;
  if (pj.finDate && pj.finDate !== "TBC" && !isCountingDown(pj.finDate) && !pj.publicRound) return false;
  return true;
};

export const isStarted = pj => {
  if (pj.endDate && pj.endDate !== "TBC") return !isCountingDown(pj.endDate);
  return false;
};

export const haveContract = pj => {
  if (pj.tokenAbi && pj.tokenAddress && pj.projectAbi && pj.projectAddress) return true;
  return false;
};

// Handler for "IDO starts in: "
export const timerStatusHandler = pj => {
  if (pj.ended) return "Ended";
  if (!pj.endDate || pj.endDate === "TBC") return "Coming Soon";
  if (!pj.whitelistUrl || !pj.tokenAbi || !pj.tokenAddress || !pj.projectAbi || !pj.projectAddress)
    return "Coming Soon";
  if (pj.finDate && pj.finDate !== "TBC" && !isCountingDown(pj.finDate)) return "Ended";
  if (pj.endDate) return false; // Init timer
  return "TBC";
};

// TODO: Refactor this method
// Handler for finance button
export const shouldButtonDisabled = pj => {
  if (pj.ended) return "Ended";
  if (pj.finDate && pj.finDate !== "TBC" && !isCountingDown(pj.finDate) && !pj.publicRound) return "Ended";
  if ((!pj.endDate || pj.endDate === "TBC") && !pj.whitelistUrl) return "TBC"; // No time No whitelist
  if (pj.endDate && pj.endDate !== "TBC" && !pj.whitelistUrl) return "Coming Soon"; // Have time No whitelist
  if ((!pj.tokenAbi || !pj.tokenAddress || !pj.projectAbi || !pj.projectAddress) && !pj.whitelistUrl)
    return "Coming Soon"; // Missing contract and whitelist
  return false;
};
