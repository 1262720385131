import { useEffect, useState } from "react";
import { Skeleton } from "@material-ui/lab";

function SnapRebaseTimer() {
  const [timer, setTimer] = useState("");

  const startTime = "April 12 2022 00:00:00 GMT+0800";

  let x;

  const countTime = () => {
    let countDownDate = new Date(startTime).getTime();
    x = setInterval(() => {
      let now = new Date().getTime();
      let distance = now - countDownDate;
      let minutes = Math.floor(15 - (((distance % (1000 * 60 * 60)) / (1000 * 60)) % 15));
      let seconds = Math.floor(60 - (distance % (1000 * 60)) / 1000);
      setTimer(
        `00 : ${String(minutes).length === 1 ? "0" + String(minutes) : String(minutes)} : ${
          String(seconds).length === 1 ? "0" + String(seconds) : String(seconds)
        }`,
      );
    }, 1000);
  };

  useEffect(() => {
    countTime();
    return () => {
      clearInterval(x);
    };
  }, []);

  return <>{timer ? timer : <Skeleton type="text" />}</>;
}

export default SnapRebaseTimer;
