import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Paper, Zoom, useMediaQuery } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import StakingDistribution from "./StakingDistribution";

function DashboardStakingCard({ index, pool }) {
  const [zoomed, setZoomed] = useState(false);
  const [tvl, setTvl] = useState(0);
  const [percent, setPercent] = useState(0);
  const { tvlArray, totalStakedAsset } = useSelector(state => state.snapDash);

  useEffect(() => {
    if (tvlArray[index]) {
      setTvl(tvlArray[index].toFixed(2));
      let p = ((tvlArray[index] * 100) / totalStakedAsset).toFixed(2);
      setPercent(p);
    }
  }, [totalStakedAsset]);

  return (
    <>
      <Zoom in={true} onEntered={() => setZoomed(true)}>
        <Paper className="dashstake-card">
          <Grid container className="dashstake-content">
            <Grid item xl={5} lg={5} md={5} sm={5}>
              <div className="dashstake-progress">
                <StakingDistribution percentage={percent} icon={pool.icon} />
              </div>
            </Grid>
            <Grid
              item
              xl={7}
              lg={7}
              md={7}
              sm={7}
              style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
              <div className="dashstake-figure">
                <div>{pool.name}</div>
                <div>
                  {tvl && totalStakedAsset ? (
                    <>
                      <span>
                        {"$" +
                          (tvl / 10 ** 3).toFixed(3).toLocaleString("fullwide", { useGrouping: false }).slice(0, -1) +
                          "K"}
                      </span>
                      <span>
                        {" / " +
                          (totalStakedAsset / 10 ** 3)
                            .toFixed(3)
                            .toLocaleString("fullwide", { useGrouping: false })
                            .slice(0, -1) +
                          "K"}
                      </span>
                    </>
                  ) : (
                    <Skeleton width="70px" height="20px" style={{ margin: "0 auto" }} />
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Zoom>
    </>
  );
}

export default DashboardStakingCard;
