import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Collapse, Typography, useMediaQuery, Zoom } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import "./snapStaking.scss";
import StakingDetail from "./StakingDetail";

import { getPoolFigures, getUnlockBlock } from "src/helpers/SnapStaking";

function StakingRow({ pool, selected }) {
  const [showDetail, setShowDetail] = useState(false); // Show pool's tabpanel
  const smallerScreen = useMediaQuery("(max-width: 650px)");

  const { rerender } = useSelector(state => state.snapStake);

  // Retrieving pool figures
  const [poolFigure, setPoolFigure] = useState({ earned: 0, myDeposit: 0, apy: 0, tvl: 0 });
  const [unlockBlock, setUnlockBlock] = useState("");
  useEffect(() => {
    let active = true;
    init();
    return () => {
      active = false;
    };
    async function init() {
      let fig = await getPoolFigures(
        pool.abi,
        pool.address,
        pool.stakeTokenAbi,
        pool.stakeTokenAddress,
        pool.rewardTokenAbi,
        pool.rewardTokenAddress,
        pool.apyType,
      );
      let block = await getUnlockBlock(pool.abi, pool.address);
      if (!active) return;
      setPoolFigure(fig);
      setUnlockBlock(block);
    }
  }, [pool, selected, rerender]);

  return (
    <>
      {selected !== "MYPOOLS" ||
      (poolFigure && poolFigure.myDeposit && poolFigure.myDeposit !== "0" && poolFigure.myDeposit !== "-") ? (
        <>
          <div className={showDetail ? "stakerow expanded" : "stakerow"} onClick={() => setShowDetail(!showDetail)}>
            <div className="row-item">
              <div>{pool.name}</div>
              <div hidden={smallerScreen}>{pool.formula}</div>
            </div>
            <div className="row-item">
              <div>{poolFigure && poolFigure.earned ? poolFigure.earned : <Skeleton width="30px" />}</div>
            </div>
            <div className="row-item">
              <div>
                {poolFigure && poolFigure.apy ? Number(poolFigure.apy).toFixed(2) + "%" : <Skeleton width="30px" />}
              </div>
            </div>
            <div className="row-item">
              <div>{poolFigure && poolFigure.myDeposit ? poolFigure.myDeposit : <Skeleton width="30px" />}</div>
            </div>
            <div className="row-item">
              <div>
                {poolFigure && poolFigure.tvl ? "$" + Number(poolFigure.tvl).toFixed(2) : <Skeleton width="30px" />}
              </div>
            </div>
            <div className="row-item svg-icon">
              <KeyboardArrowDownIcon size="2em" className="icon" />
            </div>
          </div>

          <Collapse in={showDetail} timeout={"auto"}>
            <StakingDetail pool={pool} poolFigure={poolFigure} unlockBlock={unlockBlock} key={rerender} />
          </Collapse>
        </>
      ) : null}
    </>
  );
}

export default StakingRow;
