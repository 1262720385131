import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LaunchpadPitch from "./LaunchpadPitch";
import LaunchpadTimer from "../../components/LaunchpadTimer";
import { Button, Grid, Paper, TextField, Typography, Zoom } from "@material-ui/core";
import "./launchpadDetail.scss";
import { allLaunchpads } from "src/helpers/AllLaunchpad";
import { error, info, success } from "src/slices/MessagesSlice";
import { isCountingDown, isActive, timerStatusHandler, shouldButtonDisabled } from "src/helpers/ProjectStatusHandler";

import {
  getAccountsThunk,
  getFundProgressThunk,
  checkWhitelistThunk,
  checkInvestStatusThunk,
  approveAllocatedAmtThunk,
  checkAllowanceThunk,
  investThunk,
  publicRoundInvestThunk,
} from "src/redux/launchpadActions";

function LaunchpadDetail({ pidx }) {
  const dispatch = useDispatch();

  const { userAddress, singleStakeProgress, whitelistAmt, approveStatus, availableInvestment, rerender } = useSelector(
    state => state.launchpad,
  );

  const [zoomed, setZoomed] = useState(false);

  const [investmentAmt, setInvestmentAmt] = useState(0);

  // Handling description tab change
  const [pitchTab, setPitchTab] = useState(1);
  const scrollRef = useRef(null);
  const handleTab = tabNum => {
    setPitchTab(tabNum);
    scrollRef.current.scrollIntoView();
  };

  // Get user account & funding progress bar
  useEffect(() => {
    dispatch(getAccountsThunk());
    dispatch(
      getFundProgressThunk(
        allLaunchpads[pidx].projectAbi,
        allLaunchpads[pidx].projectAddress,
        allLaunchpads[pidx].target,
      ),
    );
  }, []);

  // Check whitelist
  useEffect(() => {
    if (userAddress) {
      dispatch(checkWhitelistThunk(pidx));
    }
  }, [userAddress]);

  // Check allowance approve status
  useEffect(() => {
    if (userAddress || whitelistAmt) {
      dispatch(
        checkAllowanceThunk(
          // whitelistAmt,
          100000000000000000000000000000000000,
          allLaunchpads[pidx].tokenAbi,
          allLaunchpads[pidx].tokenAddress,
          allLaunchpads[pidx].projectAddress,
        ),
      );
    }
  }, [userAddress, whitelistAmt]);

  // Check user investment status
  useEffect(() => {
    if (
      userAddress &&
      // && whitelistAmt
      approveStatus
    ) {
      dispatch(
        checkInvestStatusThunk(
          // whitelistAmt,
          100000000000000000000000000000000000,
          approveStatus,
          allLaunchpads[pidx].projectAbi,
          allLaunchpads[pidx].projectAddress,
        ),
      );
    }
  }, [userAddress, whitelistAmt, approveStatus]);

  const approveAllowance = () => {
    dispatch(
      approveAllocatedAmtThunk(
        // whitelistAmt,
        100000000000000000000000000000000000,
        allLaunchpads[pidx].tokenAbi,
        allLaunchpads[pidx].tokenAddress,
        allLaunchpads[pidx].projectAddress,
      ),
    );
  };

  const invest = () => {
    // if (
    //   !/^\d*\.?\d*$/.test(investmentAmt) ||
    //   investmentAmt <= 0 ||
    //   !availableInvestment ||
    //   investmentAmt > availableInvestment
    // ) {
    //   console.log("investmentAmt: ", investmentAmt);
    //   console.log("availableInvestment: ", availableInvestment);
    //   return dispatch(error("Please enter a valid amount"));
    // }
    console.log("investmentAmt: ", investmentAmt);
    dispatch(
      investThunk(whitelistAmt, investmentAmt, allLaunchpads[pidx].projectAbi, allLaunchpads[pidx].projectAddress),
    );
  };

  const publicInvest = () => {
    if (!/^\d*\.?\d*$/.test(investmentAmt) || investmentAmt <= 0) {
      console.log("investmentAmt: ", investmentAmt);
      return dispatch(error("Please enter a valid amount"));
    }
    console.log("investmentAmt: ", investmentAmt);
    dispatch(publicRoundInvestThunk(investmentAmt, allLaunchpads[pidx].projectAbi, allLaunchpads[pidx].projectAddress));
  };

  const openLink = link => {
    window.open(link, "_blank");
  };

  return (
    <>
      <div className="launchpad-detail">
        <div id="launchpad-detail-view">
          <div className="launchpad-return">
            <Typography vairant="h6" color="textSecondary">
              <Link to="/launchpad">{"< Back To SnapLaunch"}</Link>
            </Typography>
            <br />
          </div>
          <Zoom in={true} onEntered={() => setZoomed(true)}>
            <Paper className="launchpad-detail-card">
              <div className="card-banner">
                <div className="card-logo">
                  <img src={allLaunchpads[pidx].logo} alt="Project Logo" />
                </div>
                <div className="launchpad-detail-header">
                  <Typography variant="h2" color="textPrimary">
                    <b>{allLaunchpads[pidx].name}</b>
                  </Typography>
                  <Typography variant="h6" color="textPrimary">
                    {allLaunchpads[pidx].subtitle}
                  </Typography>
                </div>
              </div>
              <Grid container className="grid-container">
                <Grid item xs={12} sm={12} md={12} lg={5}>
                  <div className="launchpad-detail-finance" key={rerender}>
                    <div className="launchpad-detail-countdown">
                      <Typography variant="h5" color="textPrimary">
                        IDO starts in :{" "}
                        {timerStatusHandler(allLaunchpads[pidx]) ? (
                          timerStatusHandler(allLaunchpads[pidx])
                        ) : (
                          <LaunchpadTimer endDate={allLaunchpads[pidx].endDate} />
                        )}
                      </Typography>
                    </div>
                    <div className="launchpad-detail-raise">
                      <Typography variant="h5" color="textSecondary">
                        <span>Fund raise goal : </span>
                      </Typography>
                      <br />
                      <Typography variant="h2" color="textSecondary">
                        <span>{"$" + allLaunchpads[pidx].target}</span>
                      </Typography>
                      <br />
                      <Typography variant="h6" color="textSecondary" hidden={!isActive(allLaunchpads[pidx])}>
                        Total Raise :
                      </Typography>
                      <div className="progress-bar-1" hidden={!isActive(allLaunchpads[pidx])}>
                        <div
                          className="progress-1"
                          style={
                            allLaunchpads[pidx].raised && allLaunchpads[pidx].target
                              ? { width: `${(allLaunchpads[pidx].raised / allLaunchpads[pidx].target) * 100}%` }
                              : { width: `${singleStakeProgress}%` }
                          }
                        ></div>
                      </div>
                      <br />
                      <div className="launchpad-detail-allo">
                        <Typography variant="h6" color="textSecondary" hidden={!isActive(allLaunchpads[pidx])}>
                          Allocation : <span>{allLaunchpads[pidx].allocation}</span>
                        </Typography>
                        <br />
                        <Typography variant="h6" color="textSecondary">
                          Price per token : <span>{"$" + allLaunchpads[pidx].pricePerToken}</span>
                        </Typography>
                      </div>
                    </div>

                    {/* Interactive Section */}
                    <div className="detail-action-area">
                      {shouldButtonDisabled(allLaunchpads[pidx]) ? (
                        // NOTE: TBC / Coming Soon / Ended
                        <div className="detail-button-group">
                          <Button variant="outlined" disabled>
                            <div>{shouldButtonDisabled(allLaunchpads[pidx])}</div>
                          </Button>
                        </div>
                      ) : allLaunchpads[pidx].whitelistUrl && !whitelistAmt ? (
                        // NOTE: Have whitelist but user NOT in whitelist => Whitelist
                        <div className="detail-button-group">
                          <Button variant="outlined" onClick={() => openLink(allLaunchpads[pidx].whitelistUrl)}>
                            <div>Whitelist</div>
                          </Button>
                        </div>
                      ) : whitelistAmt && isCountingDown(allLaunchpads[pidx].endDate) ? (
                        // NOTE: Have allocated amt & endDate not reached => Coming Soon
                        <div className="detail-button-group">
                          <Button variant="outlined" disabled>
                            <div>Coming Soon</div>
                          </Button>
                        </div>
                      ) : (whitelistAmt || allLaunchpads[pidx].publicRound) &&
                        !isCountingDown(allLaunchpads[pidx].endDate) &&
                        allLaunchpads[pidx].endDate &&
                        allLaunchpads[pidx].endDate !== "TBC" &&
                        !approveStatus ? (
                        // NOTE: Have allocated amt & endDate reached & NOT approved => Approve
                        <div className="detail-button-group" hidden={!whitelistAmt && !approveStatus}>
                          <Button variant="outlined" color="primary" onClick={approveAllowance}>
                            {whitelistAmt ? "Approve " + whitelistAmt : "Approve"}
                          </Button>
                        </div>
                      ) : userAddress &&
                        whitelistAmt &&
                        approveStatus &&
                        allLaunchpads[pidx].endDate &&
                        !isCountingDown(allLaunchpads[pidx].endDate) &&
                        isCountingDown(allLaunchpads[pidx].finDate) ? (
                        // NOTE: Have allocated amt & endDate reached & approved & finDate not reached => Invest
                        <div className="invest-group">
                          <div>
                            <div>Available: {availableInvestment}</div>
                            <br />
                            <TextField
                              label={"Amount of " + allLaunchpads[pidx].tokenSymbol + " to invest"}
                              variant="outlined"
                              size="small"
                              color="primary"
                              defaultValue={investmentAmt}
                              type="Number"
                              min="0"
                              onChange={e => setInvestmentAmt(Number(e.currentTarget.value))}
                              disabled={!availableInvestment}
                            />
                          </div>
                          <div className="detail-button-group">
                            <Button variant="outlined" onClick={invest} disabled={!availableInvestment}>
                              <div>Invest</div>
                            </Button>
                          </div>
                        </div>
                      ) : !isCountingDown(allLaunchpads[pidx].endDate) &&
                        allLaunchpads[pidx].endDate !== "TBC" &&
                        allLaunchpads[pidx].endDate &&
                        allLaunchpads[pidx].publicRound ? (
                        // NOTE: Have endDate reached & public round allowed => Invest
                        <div className="invest-group">
                          <div>
                            <br />
                            <br />
                            <TextField
                              label={"Amount of " + allLaunchpads[pidx].tokenSymbol + " to invest"}
                              variant="outlined"
                              size="small"
                              color="primary"
                              defaultValue={investmentAmt}
                              type="Number"
                              min="0"
                              onChange={e => setInvestmentAmt(Number(e.currentTarget.value))}
                            />
                          </div>
                          <div className="detail-button-group">
                            <Button variant="outlined" onClick={publicInvest}>
                              <div>Invest</div>
                            </Button>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={7}>
                  <div className="launchpad-detail-desc">
                    <div className="card-img-top">
                      <img
                        src={allLaunchpads[pidx].banner}
                        alt="Project Banner"
                        style={{
                          minWidth: "290px",
                          minHeight: "155px",
                          width: "833px",
                          height: "200px",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                    <div className="launchpad-detail-pitch" ref={scrollRef}>
                      <div className="pitch-nav">
                        <Typography variant="h5" color="textPrimary">
                          {allLaunchpads[pidx].tabs.map((tabTitle, i) => (
                            <span
                              key={i}
                              onClick={() => handleTab(i + 1)}
                              className={pitchTab === i + 1 ? "active" : ""}
                            >
                              {tabTitle}
                            </span>
                          ))}
                        </Typography>
                        <hr />
                      </div>
                      <br />
                      <div className="pitch-content">
                        <LaunchpadPitch tabNumber={pitchTab} pj={pidx + 1} pidx={pidx} />
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Zoom>
        </div>
      </div>
    </>
  );
}

export default LaunchpadDetail;
