import SnapexIcon from "../../assets/images/snapex-logo.png";
import "./notfound.scss";

export default function NotFound() {
  return (
    <div id="not-found">
      <div className="not-found-header">
        <a href="https://www.snapex.com/" target="_blank">
          <img className="branding-header-icon" src={SnapexIcon} alt="SnapEx" />
        </a>

        <h2 style={{ textAlign: "center" }}>Page not found</h2>
      </div>
    </div>
  );
}
