import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { trim } from "../../helpers";
import { Grid, Paper, Typography, Zoom } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { getCurrentUSDTPrice, getTotalSnapSupply } from "src/redux/snapdashboard/dashboardActions";
import { getCalApyThunk, checkAccountBalance } from "src/redux/calculator/calculatorActions";
import SnapRebaseTimer from "src/components/SnapRebaseTimer/SnapRebaseTimer";
import "./account.scss";

function Account() {
  const dispatch = useDispatch();
  const [zoomed, setZoomed] = useState(false);
  const { currentUSDTPrice, totalSnapSupply, circSnapSupply } = useSelector(state => state.snapDash);
  const { apy, userSNAPBal, userROI } = useSelector(state => state.calculator);
  const trimmedROIUSDTPrice = trim(currentUSDTPrice * 0.98541586, 2);
  useEffect(() => {
    dispatch(getCurrentUSDTPrice());
    dispatch(getTotalSnapSupply());
    dispatch(getCalApyThunk());
    dispatch(checkAccountBalance());
  }, []);

  return (
    <>
      <div id="account-view">
        {/* <Zoom in={true} onEntered={() => setZoomed(true)}>
          <Paper className="papa-card">
            <div className="card-left">
              <Typography variant="h6">Total Earned</Typography>
              <br />
              <Typography variant="h1" className="highlight">
                $-
              </Typography>
              <br />
              <Typography variant="h6">
                {"-"} SNAP ({"0.00"} increase)
              </Typography>
            </div>
          </Paper>
        </Zoom> */}

        <Zoom in={true} onEntered={() => setZoomed(true)}>
          <Paper className="papa-card">
            <Grid container className="grid-container">
              <Grid item xs={12} sm={12} md={12} lg={6} className="card-left">
                <Typography variant="h6">APY</Typography>
                <br />
                <Typography variant="h2">{apy ? apy + "%" : <Skeleton width="100px" />}</Typography>
                <br />
                <Typography variant="h6">Daily ROI {"2.312426"}%</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} className="card-right">
                <Typography variant="h6">Your Balance</Typography>
                <br />
                <Typography variant="h2">
                  {userSNAPBal && currentUSDTPrice ? (
                    "$" + (userSNAPBal / 10 ** 18) * currentUSDTPrice
                  ) : (
                    <Skeleton width="100px" />
                  )}
                </Typography>
                <br />
                <Typography variant="h6">
                  {userSNAPBal ? userSNAPBal / 10 ** 18 : <Skeleton width="100px" />} SNAP
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Zoom>

        <Zoom in={true} onEntered={() => setZoomed(true)}>
          <Paper className="papa-card">
            <Grid container className="grid-container">
              <Grid item xs={12} sm={12} md={12} lg={6} className="card-left">
                <Typography variant="h6">Next Rebase:</Typography>
                <br />
                <Typography variant="h2">
                  <SnapRebaseTimer />
                </Typography>
                <br />
                <Typography variant="h6">Interest Coming In Your Wallet</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} className="card-right">
                <Typography variant="h6">Your Earnings/Daily</Typography>
                <br />
                <Typography variant="h2">
                  {userROI && currentUSDTPrice ? (
                    "$" + (userROI / 10 ** 18) * currentUSDTPrice
                  ) : (
                    <Skeleton width="100px" />
                  )}
                </Typography>
                <br />
                <Typography variant="h6">{userROI ? userROI / 10 ** 18 : <Skeleton width="100px" />} SNAP</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Zoom>

        <Zoom in={true} onEntered={() => setZoomed(true)}>
          <Paper className="papa-card">
            <div className="card-left">
              <div className="detail-row">
                <Typography variant="h6">Current SNAP Price</Typography>
                <br />
                <Typography variant="h6" className="highlight">
                  {currentUSDTPrice ? Number(currentUSDTPrice).toFixed(6) + "USD" : <Skeleton width="100px" />}
                </Typography>
              </div>
              <br />
              <br />
              <br />
              <div className="detail-row">
                <Typography variant="h6">Next Reward Amount</Typography>
                <br />
                <Typography variant="h6">
                  {userROI && currentUSDTPrice ? "$" + userROI / 10 ** 18 / 24 / 4 : <Skeleton width="100px" />}
                </Typography>
              </div>
              <br />
              <br />
              <br />
              <div className="detail-row">
                <Typography variant="h6">Next Reward Amount USD</Typography>
                <br />
                <Typography variant="h6" className="highlight">
                  {userROI && currentUSDTPrice ? (
                    "$" + ((userROI / 10 ** 18) * currentUSDTPrice) / 24 / 4
                  ) : (
                    <Skeleton width="100px" />
                  )}
                </Typography>
              </div>
              <br />
              <br />
              <br />
              <div className="detail-row">
                <Typography variant="h6">Next Reward Yield</Typography>
                <br />
                <Typography variant="h6">{""} 0.0240875 %</Typography>
              </div>
              <br />
              <br />
              <br />
              <div className="detail-row">
                <Typography variant="h6">ROI (30-Day Rate)</Typography>
                <br />
                <Typography variant="h6" className="highlight">
                  98.541586 %
                </Typography>
              </div>
              <br />
              <br />
              <br />
              <div className="detail-row">
                <Typography variant="h6">ROI (30-Day Rate) USD</Typography>
                <br />
                <Typography variant="h6" className="highlight">
                  {currentUSDTPrice ? `$${trimmedROIUSDTPrice}` : <Skeleton width="100px" />}
                </Typography>
              </div>
            </div>
          </Paper>
        </Zoom>
      </div>
    </>
  );
}

export default Account;
