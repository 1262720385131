/** Test Abi */
import { abi as TestStakeAbi } from "src/abi/snapstaking/TestStake.json";
import { abi as JezzaTestTokenAbi } from "src/abi/snapstaking/JezToken.json";
/** Token Abi */
import { abi as CakeLpAbi } from "src/abi/snapstaking/CakeLpToken.json"; // ERC20
import { abi as RoseonAbi } from "src/abi/snapstaking/RoseonToken.json"; // Roseon
/** Staking pool Abi */
import { abi as LpStakeAbi } from "src/abi/snapstaking/LpStake.json";
import { abi as SnapSingleStake45D } from "src/abi/snapstaking/SnapSingleStake45D.json";
import { abi as SnapSingleStake90D } from "src/abi/snapstaking/SnapSingleStake90D.json";
import { abi as RoseonSnapStakeAbi } from "src/abi/snapstaking/RoseonSnapStake.json";
/** Staking pool icon */
import snapBnbLpIcon from "src/assets/staking/snap-bnb-lp.png";
import snap45d from "src/assets/staking/snap-45d.png";
import snap90d from "src/assets/staking/snap-90d.png";
import snap180d from "src/assets/staking/snap-180d.png";
import snapRosn from "src/assets/staking/snap-rosn.png";

// export const test_stake = {
//   name: "TestStake",
//   formula: "BTC+SNAP",
//   abi: TestStakeAbi,
//   address: "0x345734Afe518299Aa089eb0fD780e3ba79904fa4",
//   stakeToken: "JTEST",
//   stakeTokenAbi: JezzaTestTokenAbi,
//   stakeTokenAddress: "0x35c77BEfA581Ae0F3861ceF24e7350aF5239c2c5",
//   rewardToken: "JTEST",
//   rewardTokenAbi: JezzaTestTokenAbi,
//   rewardTokenAddress: "0x35c77BEfA581Ae0F3861ceF24e7350aF5239c2c5",
//   category: ["ALL", "SNAP"],
//   pitch: [
//     "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse recusandae sapiente voluptatum tempore, ullam labore accusantium commodi alias itaque veniam minima veritatis omnis! Dolorem consectetur modi optio omnis ex qui? Ea repellat, laudantium autem voluptate sunt expedita quas illo quod. Mollitia laudantium animi eos!",
//     "Placeat, corrupti qui sed reprehenderit, minima architecto provident sit ut corporis neque voluptatem.",
//     "Illum, eum doloremque. Repellat unde accusantium quidem, rerum quo assumenda tenetur eos!",
//   ],
//   apyType: 'lp',
//   disabled: 0,
// };

export const snap_bnb_lp = {
  name: "SNAP-BNB LP",
  formula: "SNAP",
  icon: snapBnbLpIcon,
  abi: LpStakeAbi,
  address: "0x48852fFB77c50e63468051b860D3964a63110f0c", //lp staking address
  stakeToken: "SNAP-BNB LP",
  stakeTokenAbi: CakeLpAbi,
  stakeTokenAddress: "0x4979f7a4a08b16065a17ba3f4f6278f25e77fce1", //cake-lp token address
  rewardToken: "SNAP",
  rewardTokenAbi: CakeLpAbi,
  rewardTokenAddress: "0x57cd5A91c18D21DeDd72d3bcf255b60Aef290f53",
  category: ["ALL", "LP"], // Tab filtering keyword
  pitch: ["SNAP/BNB Liquidty Pool"], // Each element will be seperated by <br />
  apyType: "lp",
  disabled: 0, // 20220723 0:Active 1:EndedStake
};

export const snap_45d = {
  name: "SNAP-45D",
  formula: "SNAP",
  icon: snap45d,
  abi: SnapSingleStake45D,
  address: "0x04d8Eca830176F764112e3EEEB097115C4253950",
  stakeToken: "SNAP",
  stakeTokenAbi: CakeLpAbi,
  stakeTokenAddress: "0x57cd5A91c18D21DeDd72d3bcf255b60Aef290f53",
  rewardToken: "SNAP",
  rewardTokenAbi: CakeLpAbi,
  rewardTokenAddress: "0x57cd5A91c18D21DeDd72d3bcf255b60Aef290f53",
  category: ["ALL", "SNAP"], // Tab filtering keyword
  pitch: ["SNAP Single Staking 45 Days Lock"], // Each element will be seperated by <br />
  apyType: "single",
  disabled: 0, // 20220723 0:Active 1:EndedStake
};

export const snap_90d = {
  name: "SNAP-90D",
  formula: "SNAP",
  icon: snap90d,
  abi: SnapSingleStake90D,
  address: "0x7a0F9666cc0EcA74D705a9681c077436691BD423",
  stakeToken: "SNAP",
  stakeTokenAbi: CakeLpAbi,
  stakeTokenAddress: "0x57cd5A91c18D21DeDd72d3bcf255b60Aef290f53",
  rewardToken: "SNAP",
  rewardTokenAbi: CakeLpAbi,
  rewardTokenAddress: "0x57cd5A91c18D21DeDd72d3bcf255b60Aef290f53",
  category: ["ALL", "SNAP"], // Tab filtering keyword
  pitch: ["SNAP Single Staking 90 Days Lock"], // Each element will be seperated by <br />
  apyType: "single",
  disabled: 0, // 20220723 0:Active 1:EndedStake
};

export const snap_roseon = {
  name: "SNAP-ROSN",
  formula: "SNAP",
  icon: snapRosn,
  abi: RoseonSnapStakeAbi,
  address: "0x04953fD456C0C778d24a0147aB044BA7Cf0EdC00",
  stakeToken: "SNAP",
  stakeTokenAbi: CakeLpAbi,
  stakeTokenAddress: "0x57cd5A91c18D21DeDd72d3bcf255b60Aef290f53",
  rewardToken: "ROSN",
  rewardTokenAbi: RoseonAbi,
  rewardTokenAddress: "0x651cd665bd558175a956fb3d72206ea08eb3df5b",
  category: ["ALL", "SNAP"], // Tab filtering keyword
  pitch: ["SNAP/ROSEON Staking 60 Days Lock (END)"], // Each element will be seperated by <br />
  apyType: "single",
  disabled: 1, // 20220723 0:Active 1:EndedStake
};

export const allPools = [snap_bnb_lp, snap_45d, snap_90d, snap_roseon];
export const allPoolsMap = allPools.reduce((prev, stake) => {
  return { ...prev, [stake.name]: stake };
}, {});

export default allPools;
