import { Typography } from "@material-ui/core";

function CreoTab2() {
  return (
    <>
      <Typography variant="h5" color="textSecondary">
        Token Sale
      </Typography>
      <br />
      <table>
        <tbody>
          <tr>
            <td>
              <Typography variant="h6" color="textSecondary">
                Ticker
              </Typography>
            </td>
            <td>
              <Typography variant="h6" color="textSecondary">
                CREO
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography variant="h6" color="textSecondary">
                Sale Price
              </Typography>
            </td>
            <td>
              <Typography variant="h6" color="textSecondary">
                $0.04
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography variant="h6" color="textSecondary">
                Sale Start Time (UTC)
              </Typography>
            </td>
            <td>
              <Typography variant="h6" color="textSecondary">
                -
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography variant="h6" color="textSecondary">
                Sale End Time (UTC)
              </Typography>
            </td>
            <td>
              <Typography variant="h6" color="textSecondary">
                -
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography variant="h6" color="textSecondary">
                Token Distribution (UTC)
              </Typography>
            </td>
            <td>
              <Typography variant="h6" color="textSecondary">
                -
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography variant="h6" color="textSecondary">
                Total Raise
              </Typography>
            </td>
            <td>
              <Typography variant="h6" color="textSecondary">
                $2,875,000
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography variant="h6" color="textSecondary">
                Initial Market Cap
              </Typography>
            </td>
            <td>
              <Typography variant="h6" color="textSecondary">
                $242,000
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography variant="h6" color="textSecondary">
                Initial Token Circulation
              </Typography>
            </td>
            <td>
              <Typography variant="h6" color="textSecondary">
                6,050,000
              </Typography>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <br />
      <br />
      <Typography variant="h5" color="textSecondary">
        Vesting Schedule
      </Typography>
      <br />
      <table>
        <tbody>
          <tr>
            <td>
              <Typography variant="h6" color="textSecondary">
                Expected Date
              </Typography>
            </td>
            <td>
              <Typography variant="h6" color="textSecondary">
                Percentage
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography variant="h6" color="textSecondary">
                TGE
              </Typography>
            </td>
            <td>
              <Typography variant="h6" color="textSecondary">
                10
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography variant="h6" color="textSecondary">
                Unlock
              </Typography>
            </td>
            <td>
              <Typography variant="h6" color="textSecondary">
                Linear Unlock over 6 months
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography variant="h6" color="textSecondary">
                Vesting Platform
              </Typography>
            </td>
            <td>
              <Typography variant="h6" color="textSecondary">
                Unicrypt
              </Typography>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

export default CreoTab2;
