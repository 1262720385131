import { SvgIcon, Link } from "@material-ui/core";
import { ReactComponent as GitHub } from "../../assets/icons/github.svg";
import { ReactComponent as Medium } from "../../assets/icons/medium.svg";
import { ReactComponent as Twitter } from "../../assets/icons/twitter.svg";
import { ReactComponent as Discord } from "../../assets/icons/discord.svg";
import { ReactComponent as Telegram } from "../../assets/icons/telegram.svg";
import { ReactComponent as Facebook } from "../../assets/icons/facebook.svg";
import { ReactComponent as Youtube } from "../../assets/icons/youtube.svg";
import { ReactComponent as Linkedin } from "../../assets/icons/linkedin.svg";

export default function Social() {
  return (
    <>
      <div className="social-row">
        <Link href="https://twitter.com/snapexofficial" target="_blank">
          <SvgIcon color="primary" component={Twitter} />
        </Link>

        <Link href="https://www.facebook.com/snapextech" target="_blank">
          <SvgIcon color="primary" component={Facebook} />
        </Link>

        <Link href="https://www.youtube.com/c/SnapExOfficial" target="_blank">
          <SvgIcon color="primary" component={Youtube} />
        </Link>

        <Link href="https://www.linkedin.com/company/snapexofficial" target="_blank">
          <SvgIcon color="primary" component={Linkedin} />
        </Link>

        <Link href="https://t.me/snapex_en" target="_blank">
          <SvgIcon color="primary" component={Telegram} />
        </Link>
      </div>
      <div className="sidebar-footer">SnapEx © 2022</div>
    </>
  );
}
