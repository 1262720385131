export const THE_GRAPH_URL = "https://api.thegraph.com/subgraphs/name/papadaoofficial/papagraph";
export const EPOCH_INTERVAL = 14400;

// NOTE could get this from an outside source since it changes slightly over time
export const BLOCK_RATE_SECONDS = 2;

export const TOKEN_DECIMALS = 18;

interface IAddresses {
  [key: number]: { [key: string]: string };
}

export const addresses: IAddresses = {
  97: {
    MIM_ADDRESS: "0xcFc2f411609e5B375835688CEf14589042EA4CFC", // DREX
    // USDC_ADDRESS: "0x04068da6c83afcfa0e13ba15a6696662335d5b75",
    // PAPA_ADDRESS: "0x70b33ebC5544C12691d055b49762D0F8365d99Fe",
    PAPA_ADDRESS: "0xa8B10EE85F232e335C5964e3840CCF219Eb93F8a", // DREX
    // STAKING_ADDRESS: "0x3875AC1F19E05813000F02414b3141DC3Ff991B6", // The new staking contract
    STAKING_ADDRESS: "0xECc4fF9bd7Fecefa2d2c03d94ccb4bE23ecFF4a5", // DREX
    // STAKING_HELPER_ADDRESS: "0x166840A0f79326F74705DA4Ae0FeE085aEd89B7c", // Helper contract used for Staking only
    STAKING_HELPER_ADDRESS: "0x8C1B8E4D9B2B5027e91e5de46B06F587505EEE47", // DREX
    OLD_STAKING_ADDRESS: "0xde698Aa043F4A9548AAc041434473E9e53991430",
    OLD_STAKING_HELPER_ADDRESS: "0xeF70DA041AecbA26187191630275ba7519F4Cc5e",
    OLD_SPAPA_ADDRESS: "0x5Ee5fDd4077CaC9138BB854FAED2A40B2482cFd9",
    // SPAPA_ADDRESS: "0xDd8E1245F8B285672111C9e4051504D654f4d43A", // Original
    SPAPA_ADDRESS: "0xfB79c91b3cE12c8216D94893c35bD265B9675FbF", // DREX
    // WSPAPA_ADDRESS: "0xdFe3b23252B3599D8969E47Ee29C8a30Cbd8d8F8", // Original
    // DISTRIBUTOR_ADDRESS: "0xA5f757325213910263010d11412Efdd5C580350D",
    DISTRIBUTOR_ADDRESS: "0x8732006a16b7fa9956db70536a2d805a7e223e9a", // DREX
    // BONDINGCALC_ADDRESS: "0xA55A711Cf7adE1552f77A7127135C5156f75c83C",
    BONDINGCALC_ADDRESS: "0xBDb6858Ce34016D47DB5529484C9bc3eF4844E89", // DREX
    // BONDINGCALC_ADDRESS1: "0xA55A711Cf7adE1552f77A7127135C5156f75c83C",
    BONDINGCALC_ADDRESS1: "0xBDb6858Ce34016D47DB5529484C9bc3eF4844E89", // DREX
    // TREASURY_ADDRESS: "0x3C8e800B9f12771A5f150D0943De968ABc7A7bE1",
    TREASURY_ADDRESS: "0x436899dbaf1894afa625eb275e580d7cd38b7a17", // DREX
    REDEEM_HELPER_ADDRESS: "0xD4ec9b6E1325feb5d2E9dd4AFDF9187C9B717bC7",
    USDT_ADDRESS: "0xc7198437980c041c805a1edcba50c1ce5db95118",
    SNAP_ADDRESS: "0x57cd5A91c18D21DeDd72d3bcf255b60Aef290f53",
    SSNAP_ADDRESS: "0x57cd5A91c18D21DeDd72d3bcf255b60Aef290f53",
    SNAP_LP_ADDRESS: "0x4979f7a4a08b16065a17ba3f4f6278f25e77fce1", // SNAP Cake-lp address
  },
};

export const tokenHolderAddress = {
  SNAP_ADDRESS: "0x57cd5A91c18D21DeDd72d3bcf255b60Aef290f53",
  DEPLOYER: "0xb0416c20ba33701d4165568ae4e5b50a639dd713",
  LAUNCHPAD: "0x3c2389175f4b1834715f0d18aef7cf041ba65bb1",
  PANCAKE: "0x4979f7a4a08b16065a17ba3f4f6278f25e77fce1",
  SNAPEX: "0x57cd5a91c18d21dedd72d3bcf255b60aef290f53",
};

export const chainId = {
  BINANCE_MAINNET: "56",
  BINANCE_TESTNET: "97",
};

export const messages = {
  please_connect: "Please connect your wallet to the Smart Chain network to use.",
  please_connect_wallet: "Please connect your wallet.",
  try_mint_more: (value: string) =>
    `You're trying to mint more than the maximum payout available! The maximum mint payout is ${value} SNAP.`,
  before_minting: "Before minting, enter a value.",
  existing_mint:
    "You have an existing mint. Minting will reset your vesting period and forfeit any pending claimable rewards. We recommend claiming rewards first or using a fresh wallet. Do you still wish to proceed?",
  before_stake: "Before staking, enter a value.",
  before_unstake: "Before un staking, enter a value.",
  tx_successfully_send: "Your transaction was successful",
  balance_updated: "Your balance was successfully updated",
  nothing_to_claim: "You have nothing to claim",
  something_wrong: "Something went wrong",
  switch_to_fantom: "Switch to the Smart Chain network?",
  slippage_too_small: "Slippage too small",
  slippage_too_big: "Slippage too big",
  balance_update_soon: "Your balance will be updated soon",
};
