import { tokenAddress, chainId } from "src/constants";
import { getCurrentUSDTPrice } from "../snapdashboard/dashboardActions";
import { abi as SNAPAbi } from "src/abi/Dashboard/Snap.json";
import { tokenHolderAddress } from "src/constants";
import { error, info, success } from "src/slices/MessagesSlice";
import { checkNetwork } from "src/helpers/SnapStaking";
import axios from "axios";
import Web3 from "web3";
const web3 = new Web3(window.ethereum);

export const CAL_APY = "CAL_APY";
export const USER_SNAP_BAL = "USER_SNAP_BAL";

export const getAccount = async () => {
  try {
    if (window.ethereum) {
      let accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      if (accounts[0]) {
        return accounts[0];
      }
    } else {
      return false;
    }
  } catch (err) {
    console.log(err);
  }
};

export const getCalApyThunk = () => async dispatch => {
  try {
    checkNetwork("BINANCE_MAINNET");
    let tokenContract = new web3.eth.Contract(SNAPAbi, tokenHolderAddress.SNAP_ADDRESS);
    let currentUSDTPrice = 0.77; // Hard coded, cannot fetch price from pancake (404)
    let totalLpSupply = await tokenContract.methods.totalSupply().call();
    let tvl = 1600; // TODO: Hard coded, totalPoolStaked method missing
    let apy = (420690.88).toFixed(2);
    dispatch({
      type: CAL_APY,
      payload: apy,
    });
  } catch (err) {
    console.log(err);
  }
};

export const checkAccountBalance = () => async dispatch => {
  try {
    checkNetwork("BINANCE_MAINNET");
    let tokenContract = new web3.eth.Contract(SNAPAbi, tokenHolderAddress.SNAP_ADDRESS);
    let userAddress = await getAccount();
    let accountBalance = userAddress ? await tokenContract.methods.balanceOf(userAddress).call() : "0";
    let userROI = accountBalance ? (Number(accountBalance) * 1.01) / 30 : "0";
    dispatch({
      type: USER_SNAP_BAL,
      payload: {
        accountBalance: accountBalance,
        userROI: userROI ? userROI : "0",
      },
    });
    return (accountBalance / 10 ** 18).toFixed(0);
  } catch (err) {
    console.log(err);
  }
};
