import { useEffect, useState } from "react";

function Timer({ endDate }) {
  const [cd, setCd] = useState("");

  let x;
  const countDown = () => {
    let countDownDate = new Date(endDate).getTime();
    x = setInterval(() => {
      let now = new Date().getTime();
      let distance = countDownDate - now;
      let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);
      if (Date.parse(endDate) <= Date.parse(new Date())) {
        setCd("Ongoing");
        clearInterval(x);
      } else {
        setCd(`${days}D ${hours}H ${minutes}M ${seconds}S`);
      }
    }, 1000);
  };

  useEffect(() => {
    countDown();
    return () => {
      clearInterval(x);
    };
  }, []);

  return <>{cd}</>;
}

export default Timer;
