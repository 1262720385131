import { RERENDER } from "src/helpers/SnapStaking";

const initialState = {
  rerender: 0,
};

export function snapStakeReducer(state = initialState, action) {
  switch (action.type) {
    case RERENDER:
      return { ...state, rerender: action.payload };
    default:
      return state;
  }
}
