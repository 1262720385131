import { useState, useEffect } from "react";
import Slider from "react-slick";
import DashboardStakingCard from "src/views/SnapDashboard/DashboardStakingCard";
// import SlickSliderNextArrow from "src/components/SlickSliderArrow";
// import SlickSliderPrevArrow from "src/components/SlickSliderArrow";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "src/views/SnapDashboard/dashStakeSlider.scss";

function SlickSlider({ type, dots, speed, slidesToShow, slidesToScroll, slideItems, centerMode }) {
  const [slideSettings, setSlideSettings] = useState({});

  useEffect(() => {
    setSlideSettings({
      dots,
      infinite: true,
      speed,
      autoplay: true,
      autoplaySpeed: 4000,
      cssEase: "linear",
      slidesToShow,
      slidesToScroll,
      centerMode,
      accessibility: true, //Enable tabbing and arrow key navigation
      arrows: false,
      draggable: true,
      initialSlide: 0,
      mobileFirst: true,
      pauseOnDotsHover: true,
      pauseOnFocus: true,
      pauseOnHover: true,
      // nextArrow: <SlickSliderNextArrow />,
      // prevArrow: <SlickSliderPrevArrow />,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
  }, [type, dots, speed, slidesToShow, slidesToScroll, slideItems]);

  return (
    <div>
      {slideItems && slideItems.length > 0 && (
        <Slider {...slideSettings}>
          {type === "dashstake" && slideItems.map((el, i) => <DashboardStakingCard pool={el} key={i} index={i} />)}
        </Slider>
      )}
    </div>
  );
}

export default SlickSlider;
