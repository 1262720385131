import { useEffect, useState } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Grid, Typography, Box, Zoom, Container, useMediaQuery } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import {
  getCurrentUSDTPrice,
  getTotalSnapSupply,
  getSnapBurn,
  getTotalLpStaked,
  getDistribution,
  getTotalStakedAssetUSDT,
} from "src/redux/snapdashboard/dashboardActions";
import "./SnapDashboard.scss";
import DashboardStakingCard from "./DashboardStakingCard";
import DashboardChart from "./DashboardChart";
import SlickSlider from "src/components/SlickSlider";
import allPools from "src/helpers/AllPools";
import Snapchart from "src/assets/images/snapchart.svg";
// import { ReactComponent as Snapchart } from "src/assets/images/snapchart.svg";

function SnapDashboard() {
  const dispatch = useDispatch();
  const mediumScreen = useMediaQuery("(max-width: 850px)");
  const smallerScreen = useMediaQuery("(max-width: 650px)");
  const verySmallScreen = useMediaQuery("(max-width: 488px)");
  const [zoomed, setZoomed] = useState(false);
  const { currentUSDTPrice, totalSnapSupply, snapBurn, snapTotalLpStaked, snapDistribution, totalStakedAsset } =
    useSelector(state => state.snapDash);

  useEffect(() => {
    dispatch(getCurrentUSDTPrice());
    dispatch(getTotalSnapSupply());
    dispatch(getSnapBurn());
    dispatch(getTotalLpStaked());
    dispatch(getDistribution());
    dispatch(getTotalStakedAssetUSDT());
  }, []);

  return (
    <>
      <div id="snap-dashboard">
        <Zoom in={true} onEntered={() => setZoomed(true)}>
          <Paper className="snapdash-card">
            <Grid container alignItems="flex-start" className="snapdash-card-gridcon">
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="snapdash-card-griditem">
                <div className="figures">
                  <Typography variant="h1" className="label">
                    <b>Circulating Supply</b>
                  </Typography>
                  <Typography variant="h3" className="data">
                    <span className="highlight">
                      {totalSnapSupply ? (
                        (totalSnapSupply / 10 ** 6).toFixed(2) + "M"
                      ) : (
                        <Skeleton width="130px" style={{ display: "inline-block" }} />
                      )}
                    </span>
                    {totalSnapSupply && <span> / 2B</span>}
                  </Typography>
                  <br />
                  <Typography variant="h6" className="data">
                    <span className="highlight">
                      {totalSnapSupply ? (
                        ((totalSnapSupply / (2 * 10 ** 10)) * 100).toFixed(2) + " % "
                      ) : (
                        <Skeleton width="130px" style={{ display: "inline-block" }} />
                      )}
                    </span>
                    {totalSnapSupply && <span>of Total Supply</span>}
                  </Typography>
                  <div className="figures-row">
                    <div>
                      <Typography variant="h6" className="label">
                        Current Market Cap
                      </Typography>
                      <Typography variant="h6" className="highlight data">
                        <span>
                          {totalSnapSupply && currentUSDTPrice ? (
                            ((totalSnapSupply * currentUSDTPrice) / 10 ** 6).toFixed(2) + "M"
                          ) : (
                            <Skeleton width="130px" />
                          )}
                        </span>
                      </Typography>
                    </div>
                    <div>
                      <Typography variant="h6" className="label">
                        Current Price
                      </Typography>
                      <Typography variant="h6" className="highlight data">
                        {currentUSDTPrice ? "$" + currentUSDTPrice.toFixed(4) : <Skeleton width="130px" />}
                      </Typography>
                    </div>
                    <div>
                      <Typography variant="h6" className="label">
                        Burn
                        {verySmallScreen && (
                          <>
                            <br />
                            <br />
                          </>
                        )}
                      </Typography>
                      <Typography variant="h6" className="highlight data">
                        {<span>33.6M</span>}
                        {/* {snapBurn ? snapBurn.toFixed(4) : <Skeleton width="130px" />} */}
                      </Typography>
                    </div>
                  </div>

                  {/* <br />
                  <br />
                  <Typography variant="h4" className="label">
                    <b>Total LP Staked</b>
                  </Typography>
                  <Typography variant="h5" className="highlight data">
                    {snapTotalLpStaked ? snapTotalLpStaked.toFixed(4) : <Skeleton width="130px" />}
                  </Typography> */}
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="snapdash-card-griditem">
                {snapDistribution && (
                  <div className="chart">
                    <DashboardChart />
                  </div>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Zoom>
        <br />
        <br />
        <br />
        <br />
        <div className="dashstake-slider">
          <Typography variant="h2">Staking</Typography>
          <br />
          <br />
          <Typography variant="h2" style={{ color: "#f93" }}>
            <b>{"$" + totalStakedAsset.toFixed(3).toLocaleString("fullwide", { useGrouping: true }).slice(0, -1)}</b>
          </Typography>
          <Typography variant="h5">Total value of staked assets</Typography>
          <br />
          <br />
          <SlickSlider
            type="dashstake"
            dots={true}
            speed={500}
            slidesToShow={smallerScreen ? 1 : mediumScreen ? 2 : 3}
            slidesToScroll={1}
            slideItems={allPools}
            centerMode={smallerScreen || mediumScreen || verySmallScreen ? true : false}
          />
        </div>
      </div>
    </>
  );
}

export default SnapDashboard;
