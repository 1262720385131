import { Typography } from "@material-ui/core";
import sproutie1 from "src/assets/launchpad/sproutie/sproutie1.png";
import sproutie2 from "src/assets/launchpad/sproutie/sproutie2.png";
import sproutie3 from "src/assets/launchpad/sproutie/sproutie3.png";

function SproutieTab1() {
  return (
    <>
      <Typography vairant="h6" color="textSecondary">
        Sproutie is the world’s first Metaverse NFT based game that encompasses Real World Economics that aims to
        increase blockchain adoption of non-crypto users and existing crypto users through play to earn mechanics.
      </Typography>
      <br />
      <br />
      <br />
      <Typography variant="h5" color="textSecondary">
        HIGHLIGHTS
      </Typography>
      <br />

      <ul>
        <li>
          <Typography variant="h6" color="textSecondary">
            First Project in Q1 2021 to have 11 Launchpads + 1 IEO
          </Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6" color="textSecondary">
            Time Limited NFTs and Time Extendable NFTs
          </Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6" color="textSecondary">
            Self Sustaining Ecosystem
          </Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6" color="textSecondary">
            Multiple Utilization Channels for tokens
          </Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6" color="textSecondary">
            Revolutionary Twin Dao engine model
          </Typography>
          <br />
        </li>
      </ul>
      <br />
      <br />
      <br />

      <Typography variant="h5" color="textSecondary">
        PRODUCT
      </Typography>
      <br />
      <Typography variant="h3" color="textSecondary">
        <b>Sustainability and Scalability through twin governance structure</b>
      </Typography>
      <br />

      <ul>
        <li>
          <Typography variant="h6">
            Multi-dimensional tokens ($BEAN and $SPROUT) utilities that balances overall demand & supply.
          </Typography>
          <br />
          <ul>
            <li>
              <Typography variant="h6">
                Ensuring that the ecosystem will be well balanced through Twin Voting Governance Structures.
              </Typography>
              <br />
            </li>
            <li>
              <Typography variant="h6">Multiple channels that require using both $BEAN and $SPROUT.</Typography>
              <br />
            </li>
            <li>
              <Typography variant="h6">Helps to reduce the selling pressure.</Typography>
              <br />
            </li>
          </ul>
        </li>
        <br />
        <li>
          <Typography variant="h6">Buybacks and burning of reward tokens.</Typography>
          <br />
          <ul>
            <li>
              <Typography variant="h6">
                The Treasury will allow both Governance Arms to vote on the amount to be burned weekly and the amount to
                be used to buyback tokens.
              </Typography>
              <br />
            </li>
          </ul>
        </li>
        <br />
        <li>
          <Typography variant="h6">Time Limited NFTs to avoid over supply.</Typography>
          <br />
          <ul>
            <li>
              <Typography variant="h6">
                This allows fair-play within the entire Sproutie Metaverse where every NFT has a time limit. Just like
                hiring an employee for your company.
              </Typography>
              <br />
            </li>
          </ul>
        </li>
        <br />
        <li>
          <Typography variant="h6">Time Extendable NFTs through token utility.</Typography>
          <br />
          <ul>
            <li>
              <Typography variant="h6">$SPROUT is used to extend the time span of the NFTs</Typography>
              <br />
            </li>
            <li>
              <Typography variant="h6">$BEAN is used to upgrade and acquire more NFTs</Typography>
              <br />
            </li>
          </ul>
        </li>
        <br />
        <li>
          <Typography variant="h6">Future NFT lending mechanism to maximize efficiency for holders.</Typography>
          <br />
          <ul>
            <li>
              <Typography variant="h6">
                For speculators, the marketplace is available for people with NFTs to lease out their NFTs for a portion
                of rewards when a player/scholar utilizes/borrows them.
              </Typography>
              <br />
            </li>
          </ul>
        </li>
        <br />
        <li>
          <Typography variant="h6">Fair-trade in the marketplace through bartering.</Typography>
          <br />
          <ul>
            <li>
              <Typography variant="h6">
                Every user will be able to bargain and trade their crops, seeds and even dishes in the marketplace if
                they do not wish to use $BEAN or $BNB. Just like a traditional marketplace.
              </Typography>
              <br />
            </li>
          </ul>
        </li>
        <br />
        <li>
          <Typography variant="h6">Circular in game economy.</Typography>
          <br />
          <ul>
            <li>
              <Typography variant="h6">
                Ensuring that players stay within the game and future investors re-invest. Through rewards, auto-swap
                and constant scaling.
              </Typography>
              <br />
            </li>
          </ul>
        </li>
        <br />
        <li>
          <Typography variant="h6">Bi-weekly leadership board to share platform revenue.</Typography>
          <br />
          <ul>
            <li>
              <Typography variant="h6">
                Rewarding of the best improvers in game and best performers to instill fairness and maintain a
                democratic game.
              </Typography>
              <br />
            </li>
          </ul>
        </li>
        <br />
        <li>
          <Typography variant="h6">
            Upgrading / Levelling of farmers by completing more dishes and utilizing $BEAN tokens.
          </Typography>
          <br />
          <ul>
            <li>
              <Typography variant="h6">
                Users can utilize $BEAN which is our governance token to upgrade NFTs and acquire additional Farm Lands.
              </Typography>
              <br />
            </li>
          </ul>
        </li>
        <br />
        <li>
          <Typography variant="h6">In – Game Store for additional purchases and add-ons</Typography>
          <br />
          <ul>
            <li>
              <Typography variant="h6">
                Selling rare seeds, items and machinery for the players that want an edge.
              </Typography>
              <br />
            </li>
          </ul>
        </li>
        <br />
        <li>
          <Typography variant="h6">On-Ramp Mechanism through credit card payment for non-crypto players. </Typography>
          <br />
          <ul>
            <li>
              <Typography variant="h6">
                Non-crypto players, aka without metamask will even be allowed to join the game through credit card
                payment. This ensures an influx of non-crypto players to enjoy the spoils of Sproutie.
              </Typography>
              <br />
            </li>
          </ul>
        </li>
      </ul>
      <br />
      <br />
      <br />

      <Typography variant="h5" color="textSecondary">
        PROBLEMS
      </Typography>
      <br />
      <ul>
        <li>
          <Typography variant="h6">Most games are highly scalable but not sustainable after a few months.</Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">Not enough utilization streams for both Governance and Reward Tokens.</Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">
            Users/Gamers have no incentive to hold the Reward tokens which thereafter causes a sell off.
          </Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">Single Governance Model which is often manipulated.</Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">
            Endless circulating supply of NFTs which will inevitably lead to a larger amount of reward tokens being
            minted, which will mean a larger sell off, which will eventually cause the token prices to tank.
          </Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">Dictation of NFT prices by projects.</Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">Gamers only have 1 or 2 roles to choose in the game.</Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">
            NFT Gamefi projects are fighting amongst the same crowd of users for a marketshare.
          </Typography>
          <br />
        </li>
      </ul>
      <br />
      <br />
      <br />

      <Typography variant="h5" color="textSecondary">
        SOLUTION
      </Typography>
      <br />
      <img src={sproutie1} alt="Sproutie Solution" style={{ width: "100%" }} />
      <br />
      <br />
      <Typography variant="h6">The above chart depicts Sproutie’s Ecosystem.</Typography>
      <br />
      <ul>
        <li>
          <Typography vairant="h6">
            Twin Governance model where NFT holders govern the Marketplace and $BEAN token holders govern the In-game
            Store. Double decentralization.
          </Typography>
          <br />
        </li>
        <li>
          <Typography vairant="h6">Multiple Utilization Avenues for both $BEAN and $SPROUT tokens.</Typography>
          <br />
        </li>
        <li>
          <Typography vairant="h6">
            Ensured utilization of $SPROUT tokens will inevitably help to maintain price.
          </Typography>
          <br />
        </li>
        <li>
          <Typography vairant="h6">
            Platform Treasury will allocate funds to buy-back and burn tokens based on what the community votes for.
          </Typography>
          <br />
        </li>
        <li>
          <Typography vairant="h6">
            Gamers can choose to be farmers, traders at the marketplace or even chefs.
          </Typography>
          <br />
        </li>
      </ul>
      <br />
      <img src={sproutie2} alt="market" style={{ width: "100%" }} />
      <br />
      <br />
      <Typography variant="h6">
        The above chart shows how both Crypto and Non-Crypto Savvy users can join the game. With the credit card on-ramp
        mechanism, Sproutie aims to target non crypto users to join our game and earn while they play the game.
      </Typography>
      <br />
      <br />
      <br />
      <Typography variant="h5">MARKET</Typography>
      <br />
      <img src={sproutie3} alt="target" style={{ width: "100%" }} />
      <br />
      <br />
      <br />
      <Typography variant="h5" color="textSecondary">
        INVESTORS & PARTNERS
      </Typography>
      <br />
      <ul>
        <li>
          <Typography variant="h6">Plutus VC</Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">Mint Ventures</Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">New Era Capital</Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">Hinterland</Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">Rogue Digital Ventures</Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">Jigsaw Capital</Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">Quantech</Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">ABCC Exchange</Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">Reubiks Capital</Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">Ekta</Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">MetaSeer</Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">
            Non Fungible Gaming (NFG) <br /> Vendil Gaming Guild (VGG)
          </Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">Unix Game Guild</Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">EndGame</Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">Probit Global</Typography>
          <br />
        </li>
      </ul>
      <br />
      <br />
      <br />

      <Typography variant="h5" color="textSecondary">
        TOKEN UTILITY
      </Typography>
      <br />
      <Typography variant="h6" color="textSecondary">
        $BEAN (Governance Token)
      </Typography>
      <br />
      <ul>
        <li>
          <Typography variant="h6">DAO Governance for all in-game mechanics and in-game store</Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">Leasing of farmers</Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">Leasing of lands</Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">Upgrade farmers level for increased passive output</Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">Base currency for Market Place Trading transactions</Typography>
          <br />
        </li>
      </ul>
      <br />
      <Typography variant="h6" color="textSecondary">
        $SPROUT (Reward Token)
      </Typography>
      <ul>
        <li>
          <Typography variant="h6">Extend the expiry date of farmers</Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">Extend the expiry date of lands</Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">Placing of ads and taking the advertised ingredients on Barter Exchange</Typography>
          <br />
        </li>
        <li>
          <Typography variant="h6">
            Rewards for completing quests (collating various ingredients to cook specific dish)
          </Typography>
          <br />
        </li>
      </ul>
    </>
  );
}

export default SproutieTab1;
