import { configureStore } from "@reduxjs/toolkit";
import accountReducer from "./slices/AccountSlice";
import bondingReducer from "./slices/BondSlice";
import appReducer from "./slices/AppSlice";
import pendingTransactionsReducer from "./slices/PendingTxnsSlice";
import messagesReducer from "./slices/MessagesSlice";
// reducers are named automatically based on the name field in the slice
// exported in slice files by default as nameOfSlice.reducer
import { launchpadReducer } from "./redux/launchpadReducers";
import { snapStakeReducer } from "./redux/snapstaking/snapstakingReducers";
import { snapDashboardReducer } from "./redux/snapdashboard/dashboardReducers";
import { calculatorReducer } from "./redux/calculator/calculatorReducers";

const store = configureStore({
  reducer: {
    //   we'll have state.account, state.bonding, etc, each handled by the corresponding
    // reducer imported from the slice file
    account: accountReducer,
    bonding: bondingReducer,
    app: appReducer,
    pendingTransactions: pendingTransactionsReducer,
    messages: messagesReducer,
    launchpad: launchpadReducer,
    snapStake: snapStakeReducer,
    snapDash: snapDashboardReducer,
    calculator: calculatorReducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
