import { Typography } from "@material-ui/core";

function StrtButtonTab2() {
  return (
    <>
      <Typography variant="h5" color="textSecondary">
        <b>Project Key Metrics</b>
      </Typography>
      <br />
      <table>
        <tbody>
          <tr>
            <td>
              <Typography variant="h6" color="textSecondary">
                Sale Price
              </Typography>
            </td>
            <td>
              <Typography variant="h6" color="textSecondary">
                1 STRT = $0.06
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography variant="h6" color="textSecondary">
                Sale Start Time (UTC)
              </Typography>
            </td>
            <td>
              <Typography variant="h6" color="textSecondary">
                1pm, 18th March 2022 (UTC)
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography variant="h6" color="textSecondary">
                Sale End Time (UTC)
              </Typography>
            </td>
            <td>
              <Typography variant="h6" color="textSecondary">
                24 hours
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography variant="h6" color="textSecondary">
                Token Distribution (UTC)
              </Typography>
            </td>
            <td>
              <Typography variant="h6" color="textSecondary">
                After open trade at CEX & DEX
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography variant="h6" color="textSecondary">
                Initial Market Cap
              </Typography>
            </td>
            <td>
              <Typography variant="h6" color="textSecondary">
                $430,000
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography variant="h6" color="textSecondary">
                Initial Token Circulation
              </Typography>
            </td>
            <td>
              <Typography variant="h6" color="textSecondary">
                1.75% from the total supply
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography variant="h6" color="textSecondary">
                TGE
              </Typography>
            </td>
            <td>
              <Typography variant="h6" color="textSecondary">
                20% unlock in the first day
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography variant="h6" color="textSecondary">
                Vesting Schedule
              </Typography>
            </td>
            <td>
              <Typography variant="h6" color="textSecondary">
                80% (Linear 4 months 20% monthly vesting)
              </Typography>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

export default StrtButtonTab2;
