import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, Link, TextField, Typography } from "@material-ui/core";
import "./snapStaking.scss";
import Progress from "./Progress";
import { approvePool, checkAccountBalance, checkApprovePool, stakePool } from "src/helpers/SnapStaking";
import { error, info, success } from "src/slices/MessagesSlice";

function Deposit({ pool }) {
  const dispatch = useDispatch();

  const { rerender } = useSelector(state => state.snapStake);

  const [stakeAmt, setStakeAmt] = useState(0);
  const [approveStatus, setApproveStatus] = useState(false);
  const [accountBalance, setAccountBalance] = useState("-");
  useEffect(() => {
    let active = true;
    getApproveStatus();
    getAccountBalance();
    return () => {
      active = false;
    };
    async function getApproveStatus() {
      let status = await checkApprovePool(pool.stakeTokenAbi, pool.stakeTokenAddress, pool.address);
      if (!active) return;
      setApproveStatus(status);
    }
    async function getAccountBalance() {
      let bal = await checkAccountBalance(pool.stakeTokenAbi, pool.stakeTokenAddress);
      if (!active) return;
      setAccountBalance(bal);
    }
  }, [pool, rerender]);

  const handleApprove = () => {
    dispatch(approvePool(pool.stakeTokenAbi, pool.stakeTokenAddress, pool.address));
  };
  const handleStake = () => {
    if (!/^\d*\.?\d*$/.test(stakeAmt) || stakeAmt <= 0) {
      dispatch(error("Please enter a valid amount"));
    } else {
      dispatch(stakePool(pool.stakeTokenAbi, pool.stakeTokenAddress, pool.abi, pool.address, stakeAmt));
    }
  };

  return (
    <>
      <div className="deposit">
        <div className="deposit-text">
          {pool.pitch && pool.pitch.length > 0
            ? pool.pitch.map((text, i) => (
                <div key={i}>
                  <div>{text}</div>
                  <br />
                </div>
              ))
            : null}
        </div>
        <div className="deposit-panel">
          <Grid container>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <div className="deposit-action">
                <div>Available: {accountBalance ? accountBalance : "-"}</div>
                <TextField
                  label={"Amount of " + pool.stakeToken + " to stake"}
                  variant="outlined"
                  size="small"
                  color="primary"
                  value={stakeAmt}
                  type="Number"
                  min="0"
                  onChange={e => setStakeAmt(e.currentTarget.value)}
                  disabled={pool.disabled === 1}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={9}>
              <div className="deposit-step">
                <Progress approveStatus={approveStatus} />
                <div className="deposit-btngroup">
                  {/* {pool.disabled === 1 ? (
                    <Button variant="outlined" disabled>
                      Ended
                    </Button>
                  ) : ( */}
                  <>
                    <Button
                      variant="outlined"
                      className={approveStatus ? "approved" : ""}
                      onClick={() => handleApprove()}
                      disabled={approveStatus || pool.disabled === 1}
                    >
                      <div>{approveStatus ? "Approved" : "Approve"}</div>
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => handleStake()}
                      disabled={!approveStatus || pool.disabled === 1}
                    >
                      <div>Deposit & Stake</div>
                    </Button>
                  </>
                  {/* )} */}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}

export default Deposit;
