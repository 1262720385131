import { ThemeProvider } from "@material-ui/core/styles";
import { useEffect, useState, useCallback } from "react";
import { Route, Redirect, Switch, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import useTheme from "./hooks/useTheme";
import useBonds from "./hooks/Bonds";
import { useAddress, useWeb3Context } from "./hooks/web3Context";
import { storeQueryParameters } from "./helpers/QueryParameterHelper";
import { shouldTriggerSafetyCheck } from "./helpers";
import { calcBondDetails } from "./slices/BondSlice";
import { loadAppDetails } from "./slices/AppSlice";
import { loadAccountDetails, calculateUserBondDetails } from "./slices/AccountSlice";
import { initAppData } from "src/redux/snapdashboard/dashboardActions";
import { info } from "./slices/MessagesSlice";
import {
  Stake,
  ChooseBond,
  Bond,
  TreasuryDashboard,
  Launchpad,
  LaunchpadDetail,
  SnapDashboard,
  SnapStaking,
  Account,
} from "./views";
import Sidebar from "./components/Sidebar/Sidebar.jsx";
import TopBar from "./components/TopBar/TopBar.jsx";
import NavDrawer from "./components/Sidebar/NavDrawer.jsx";
import Messages from "./components/Messages/Messages";
import NotFound from "./views/404/NotFound";
import { dark as darkTheme } from "./themes/dark.js";
import { light as lightTheme } from "./themes/light.js";
import { girth as gTheme } from "./themes/girth.js";
import "./style.scss";
import Wrap from "./views/Wrap/Wrap";
// import { mim4, papa_mim_4 } from "./helpers/AllBonds";
import { mim, papa_mim } from "./helpers/AllBonds";
import Calculator from "./views/Calculator/index";
import "@fontsource/skranji";
import { allLaunchpads } from "./helpers/AllLaunchpad";

const drawerWidth = 280;
const transitionDuration = 969;

const useStyles = makeStyles(theme => ({
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: transitionDuration,
    }),
    height: "100%",
    overflow: "auto",
    marginLeft: drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: transitionDuration,
    }),
    marginLeft: 0,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
}));

function App() {
  // useSegmentAnalytics();
  const dispatch = useDispatch();
  const [theme, toggleTheme, mounted] = useTheme();
  const location = useLocation();
  const currentPath = location.pathname + location.search + location.hash;
  const classes = useStyles();
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const isSmallerScreen = useMediaQuery("(max-width: 980px)");
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const { connect, hasCachedProvider, provider, chainID, connected, uri } = useWeb3Context();
  const address = useAddress();
  const [walletChecked, setWalletChecked] = useState(false);

  // const isAppLoading = useSelector(state => state.app.loading);
  // const isAppLoaded = useSelector(state => typeof state.app.marketPrice != "undefined"); // Hacky way of determining if we were able to load app Details.
  // let { bonds } = useBonds();
  // bonds = [...bonds, ...[mim4, papa_mim_4]];
  // bonds = [...bonds, ...[mim, papa_mim]];
  async function loadDetails(whichDetails) {
    // NOTE (unbanksy): If you encounter the following error:
    // Unhandled Rejection (Error): call revert exception (method="balanceOf(address)", errorArgs=null, errorName=null, errorSignature=null, reason=null, code=CALL_EXCEPTION, version=abi/5.4.0)
    // it's because the initial provider loaded always starts with chainID=1. This causes
    // address lookup on the wrong chain which then throws the error. To properly resolve this,
    // we shouldn't be initializing to chainID=1 in web3Context without first listening for the
    // network. To actually test rinkeby, change setChainID equal to 4 before testing.
    let loadProvider = provider;
    if (whichDetails === "app") {
      loadApp(loadProvider);
    }
    // don't run unless provider is a Wallet...
    if (whichDetails === "account" && address && connected) {
      loadAccount(loadProvider);
    }
  }

  const loadApp = useCallback(
    loadProvider => {
      // dispatch(loadAppDetails({ networkID: chainID, provider: loadProvider }));
      // bonds.map(bond => {
      //   dispatch(calcBondDetails({ bond, value: null, provider: loadProvider, networkID: chainID }));
      // });
      dispatch(initAppData());
    },
    [connected],
  );

  const loadAccount = useCallback(
    loadProvider => {
      // dispatch(loadAccountDetails({ networkID: chainID, address, provider: loadProvider }));
      // bonds.map(bond => {
      //   dispatch(calculateUserBondDetails({ address, bond, provider, networkID: chainID }));
      // });
    },
    [connected],
  );

  // The next 3 useEffects handle initializing API Loads AFTER wallet is checked
  //
  // this useEffect checks Wallet Connection & then sets State for reload...
  // ... we don't try to fire Api Calls on initial load because web3Context is not set yet
  // ... if we don't wait we'll ALWAYS fire API calls via JsonRpc because provider has not
  // ... been reloaded within App.
  useEffect(() => {
    if (hasCachedProvider()) {
      // then user DOES have a wallet
      connect().then(() => {
        setWalletChecked(true);
      });
    } else {
      // then user DOES NOT have a wallet
      setWalletChecked(true);
    }
    // We want to ensure that we are storing the UTM parameters for later, even if the user follows links
    storeQueryParameters();
    if (shouldTriggerSafetyCheck()) {
      dispatch(info("Safety Check: Always verify you're on SNAPEX DEFI!"));
    }
  }, []);

  // this useEffect fires on state change from above. It will ALWAYS fire AFTER
  useEffect(() => {
    // don't load ANY details until wallet is Checked
    if (walletChecked) {
      loadDetails("app");
    }
  }, [walletChecked]);

  // this useEffect picks up any time a user Connects via the button
  useEffect(() => {
    // don't load ANY details until wallet is Connected
    if (connected) {
      loadDetails("account");
    }
  }, [connected]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSidebarClose = () => {
    setIsSidebarExpanded(false);
  };
  let themeMode = theme === "light" ? lightTheme : theme === "dark" ? darkTheme : gTheme;

  useEffect(() => {
    themeMode = theme === "light" ? lightTheme : darkTheme;
  }, [theme]);

  useEffect(() => {
    if (isSidebarExpanded) handleSidebarClose();
  }, [location]);

  useEffect(() => {
    if (connected) {
      const updateAppDetailsInterval = setInterval(() => {
        // dispatch(loadAppDetails({ networkID: chainID, provider }));
        dispatch(initAppData());
        // bonds.map(bond => {
        //   dispatch(calcBondDetails({ bond, value: null, provider, networkID: chainID }));
        // });
      }, 1000 * 60);
      return () => {
        clearInterval(updateAppDetailsInterval);
      };
    }
  }, [connected]);

  // useEffect(() => {
  //   if (walletChecked) {
  //     const updateAccountDetailInterval = setInterval(() => {
  //       dispatch(loadAccountDetails({ networkID: chainID, address, provider: provider }));
  //       // bonds.map(bond => {
  //       //   dispatch(calculateUserBondDetails({ address, bond, provider, networkID: chainID }));
  //       // });
  //     }, 1000 * 60 * 10);
  //     return () => {
  //       clearInterval(updateAccountDetailInterval);
  //     };
  //   }
  // }, [walletChecked]);
  return (
    <ThemeProvider theme={themeMode}>
      <CssBaseline />
      <div className={`app ${isSmallerScreen && "tablet"} ${isSmallScreen && "mobile"} ${theme}`}>
        <Messages />
        <TopBar theme={theme} toggleTheme={toggleTheme} handleDrawerToggle={handleDrawerToggle} />
        <nav className={classes.drawer}>
          {isSmallerScreen ? (
            <NavDrawer mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
          ) : (
            <Sidebar />
          )}
        </nav>
        <div className={`${classes.content} ${isSmallerScreen && classes.contentShift}`}>
          <Switch>
            <Route exact path="/dashboard">
              <SnapDashboard />
            </Route>

            <Route exact path="/">
              <Redirect to="/dashboard" />
            </Route>

            <Route exact path="/account">
              <Account />
            </Route>

            {/* <Route path="/stake">
              <Stake />
            </Route> */}

            {/* <Route path="/wrap">
              <Wrap />
            </Route>

            <Route path="/bonds">
              {bonds.map((bond, i) => {
                return (
                  <Route exact key={i} path={`/bonds/${bond.name}`}>
                    <Bond bond={bond} />
                  </Route>
                );
              })}
              <ChooseBond />
            </Route> */}

            <Route exact path="/launchpad">
              <Launchpad />
            </Route>

            <Route path="/staking">
              <SnapStaking />
            </Route>

            <Route exact path="/launch">
              <Redirect to="/launchpad" />
            </Route>

            <Route path="/">
              {allLaunchpads.map((el, i) => {
                return (
                  <Route exact key={i} path={`/${el.linkName}`}>
                    <LaunchpadDetail pidx={i} />
                  </Route>
                );
              })}
            </Route>

            {/* <Route path="/calculator">
              <Calculator />
            </Route> */}

            <Route component={NotFound} />
          </Switch>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
