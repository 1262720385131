import Web3 from "web3";
const web3 = new Web3(window.ethereum);
import * as Papa from "papaparse";
import { error, info, success } from "src/slices/MessagesSlice";
import { metamaskErrorWrap } from "src/helpers/MetamaskErrorWrap";

// Importing all whitelist csv
import SAMPLE_CSV from "src/assets/csv/SAMPLE_whitelist.csv";
// import SPROUTIE_CSV from "src/assets/csv/SPROUTIE_whitelist.csv";
import CREO_CSV from "src/assets/csv/CREO_whitelist.csv";
import TEST_CSV from "src/assets/csv/CREO_whitelist.csv";
export const csvArr = [SAMPLE_CSV, CREO_CSV, TEST_CSV];

export const USER_ADDRESS = "USER_ADDRESS";
export const SINGLE_STACK_PROGRESS = "SINGLE_STACK_PROGRESS";
export const CHECK_WHITELIST = "CHECK_WHITELIST";
export const CHECK_APPROVE = "CHECK_APPROVE";
export const CHECK_USER_INVEST_STATUS = "USER_INVEST_STATUS";
export const RERENDER = "RERENDER";

export const getAccount = async () => {
  try {
    if (window.ethereum) {
      let accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      if (accounts[0]) {
        return accounts[0];
      }
    } else {
      return false;
    }
  } catch (err) {
    console.log(err);
  }
};

export const getAccountsThunk = () => async dispatch => {
  try {
    if (window.ethereum) {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      if (accounts[0] && accounts !== "undefined") {
        dispatch({
          type: USER_ADDRESS,
          payload: accounts[0],
        });
        return accounts[0];
      } else {
        return "";
      }
    }
  } catch (err) {
    console.log("Error: ", err);
  }
};

/** For launchpad page without updating store */
export const getFundProgress = async (projectAbi, projectAddress, projectTarget) => {
  try {
    if (!projectAbi || !projectAddress || !projectTarget) {
      return 0;
    }
    let projectContract = new web3.eth.Contract(projectAbi, projectAddress);
    let stackedAmt = await projectContract.methods.totalpoolstacked().call();
    if (parseInt(stackedAmt) >= projectTarget * 10 ** 18) {
      return 100;
    } else {
      let progress = (parseInt(stackedAmt) * 100) / (projectTarget * 10 ** 18);
      return progress;
    }
  } catch (err) {
    console.log(err);
  }
};

/** For launchpad detail page */
export const getFundProgressThunk = (projectAbi, projectAddress, projectTarget) => async dispatch => {
  try {
    if (!projectAbi || !projectAddress || !projectTarget) {
      dispatch({
        type: SINGLE_STACK_PROGRESS,
        payload: 0,
      });
      return;
    }
    let projectContract = new web3.eth.Contract(projectAbi, projectAddress);
    let stackedAmt = await projectContract.methods.totalpoolstacked().call();
    if (parseInt(stackedAmt) >= projectTarget * 10 ** 18) {
      dispatch({
        type: SINGLE_STACK_PROGRESS,
        payload: 100,
      });
    } else {
      let progress = (parseInt(stackedAmt) * 100) / (projectTarget * 10 ** 18);
      dispatch({
        type: SINGLE_STACK_PROGRESS,
        payload: progress,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const checkWhitelistThunk = projectIndex => async dispatch => {
  try {
    let userAddress = await getAccount();
    if (userAddress) {
      let allocatedAmt = 0;
      await Papa.parse(csvArr[projectIndex], {
        download: true,
        header: true,
        complete: result => {
          let whitelist = result.data;
          for (let i = 0; i < whitelist.length; i++) {
            if (whitelist[i].address.toLowerCase() === userAddress) {
              allocatedAmt = whitelist[i].AllowPurchaseAmount;
            }
          }
          dispatch({
            type: CHECK_WHITELIST,
            payload: allocatedAmt,
          });
          return;
        },
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const approveAllocatedAmtThunk = (whitelistAmt, tokenAbi, tokenAddress, projectAddress) => async dispatch => {
  try {
    let userAddress = await getAccount();
    if (userAddress && whitelistAmt) {
      let tokenContract = new web3.eth.Contract(tokenAbi, tokenAddress);
      let res = await tokenContract.methods
        .approve(projectAddress, "1000000000000000000000000000000000000000000")
        .send({ from: userAddress });
      if (res.transactionHash) {
        dispatch(success("Approved, txid: " + res.transactionHash));
      }
    }
  } catch (err) {
    console.log("Error: ", err);
    return metamaskErrorWrap(err, dispatch);
  }
};

export const checkAllowanceThunk = (whitelistAmt, tokenAbi, tokenAddress, projectAddress) => async dispatch => {
  try {
    let userAddress = await getAccount();
    if (!whitelistAmt || !tokenAbi || !tokenAddress || !projectAddress) {
      dispatch({
        type: CHECK_APPROVE,
        payload: false,
      });
      return;
    }
    if (userAddress && whitelistAmt) {
      let tokenContract = new web3.eth.Contract(tokenAbi, tokenAddress);
      let allowance = await tokenContract.methods.allowance(userAddress, projectAddress).call();
      if (allowance > 0) {
        // console.log("User allowance approved");
        dispatch({
          type: CHECK_APPROVE,
          payload: true,
        });
      } else {
        // console.log("User has not approved");
        dispatch({
          type: CHECK_APPROVE,
          payload: false,
        });
      }
    }
  } catch (err) {
    console.log("Error: ", err);
  }
};

/* Pseudo code for allowing user invest > 1 time
launchpad_con.methods.stakeBalanceOf(user_address).call((investedAmount)=>{
parseInt(investedAmount)/10**18>=csvalocation?cannot invest:remaining invest amount = csvalocation - investedAmount
});
*/
export const checkInvestStatusThunk = (whitelistAmt, approveStatus, projectAbi, projectAddress) => async dispatch => {
  try {
    let userAddress = await getAccount();
    if (!userAddress || !whitelistAmt || !approveStatus || !projectAbi || !projectAddress) {
      dispatch({
        type: CHECK_USER_INVEST_STATUS,
        payload: 0,
      });
      return;
    }
    let projectContract = new web3.eth.Contract(projectAbi, projectAddress);
    let investedAmt = await projectContract.methods.stakeBalanceOf(userAddress).call();
    if (Number(investedAmt) >= whitelistAmt * 10 ** 18) {
      dispatch({
        type: CHECK_USER_INVEST_STATUS,
        payload: 0,
      });
    } else {
      dispatch({
        type: CHECK_USER_INVEST_STATUS,
        paylaod: whitelistAmt - Number(investedAmt) / 10 ** 18,
      });
    }
  } catch (err) {
    console.log("Error: ", err);
    return metamaskErrorWrap(err, dispatch);
  }
};

export const investThunk = (whitelistAmt, investmentAmt, projectAbi, projectAddress) => async dispatch => {
  try {
    if (
      // !whitelistAmt ||
      !investmentAmt ||
      !projectAbi ||
      !projectAddress
    )
      return;
    let userAddress = await getAccount();
    if (userAddress && investmentAmt) {
      let projectContract = new web3.eth.Contract(projectAbi, projectAddress);
      let investedAmt = await projectContract.methods.stakeBalanceOf(userAddress).call();
      let investSum = parseInt(investedAmt) / 10 ** 18 + parseInt(investmentAmt);
      if (investSum <= whitelistAmt) {
        let res = projectContract.methods
          .purchase((investmentAmt * 10 ** 18).toLocaleString("fullwide", { useGrouping: false }))
          .send({ from: userAddress });
        if (res.transactionHash) {
          dispatch(success("Invested, txid: " + res.transactionHash));
          dispatch({
            type: RERENDER,
            payload: Math.random(),
          });
        }
      }
    }
  } catch (err) {
    console.log("Error: ", err);
    return metamaskErrorWrap(err, dispatch);
  }
};

export const publicRoundInvestThunk = (investmentAmt, projectAbi, projectAddress) => async dispatch => {
  try {
    if (!investmentAmt || !projectAbi || !projectAddress) return;
    let userAddress = await getAccount();
    if (userAddress && investmentAmt) {
      let projectContract = new web3.eth.Contract(projectAbi, projectAddress);
      let res = projectContract.methods
        .purchase((investmentAmt * 10 ** 18).toLocaleString("fullwide", { useGrouping: false }))
        .send({ from: userAddress });
      if (res.transactionHash) {
        dispatch(success("Invested, txid: " + res.transactionHash));
        dispatch({
          type: RERENDER,
          payload: Math.random(),
        });
      }
    }
  } catch (err) {
    console.log(err);
    return metamaskErrorWrap(err, dispatch);
  }
};
